import { PLAN_TYPE, Statuses } from "app/types";
import {
  Board,
  BoardPermission,
  BoardType,
  TicketGroup,
  TicketType,
} from "./types";

export const BOARDS: Board[] = [
  {
    id: "1",
    boardName: "QA bugs board",
    lastViewed: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    type: BoardType.LIST,
    permission: BoardPermission.PRIVATE,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      id: "1",
      name: "Emerson Philips",
      online: true,
      color: "#FFC803",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
  },
  {
    id: "2",
    boardName: "Dev board",
    type: BoardType.KANBAN,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    id: "3",
    boardName: "Dev tasks board",
    type: BoardType.LIST,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    id: "4",
    boardName: "Dev tasks Board 1.1.1",
    type: BoardType.KANBAN,
    permission: BoardPermission.CUSTOM,
    projects: [
      {
        id: "1",
        projectName: "EcoPower",
        color: "#2F80ED",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    id: "5",
    boardName: "Tasks 2.0",
    type: BoardType.LIST,
    permission: BoardPermission.CUSTOM,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    id: "6",
    boardName: "QA bugs  1.0.0",
    type: BoardType.LIST,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
];

export const TICKET_HEADERS = [
  {
    name: "Name",
    id: "name",
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Status",
    id: "status",
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Assignee",
    id: "assignee",
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Priority",
    id: "priority",
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Due Date",
    id: "dueDate",
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
];

export const TICKET_GROUPS: TicketGroup[] = [
  {
    status: {
      name: "To Do",
      color: "#43D98C",
    },
    tickets: [
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000004",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000005",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
  {
    status: {
      name: "In Process",
      color: "#FEB355",
    },
    tickets: [
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000003",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "In process",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000001",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "In Progress",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.BUG,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000002",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "In Progress",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
  {
    status: {
      name: "Done",
      color: "#A394FB",
    },
    tickets: [
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000006",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000007",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
];

export const PROJECTS = [
  {
    id: "1",
    name: "Digital Innovators Collective",
    icon: {
      avatar: "",
      color: "#FFFFFF",
      backgroundColor: "#5A8DF1",
    },
    owner: {
      id: "1",
      name: "Emerson Philips",
      avatar: "",
      isOnline: true,
    },
    plan: PLAN_TYPE.FREE,
    statuses: [
      Statuses.ABANDONED,
      Statuses.DONE,
      Statuses.IN_CODE_REVIEW,
      Statuses.IN_PROGRESS,
      Statuses.IN_STAGING,
      Statuses.REOPENED,
      Statuses.TODO,
      Statuses.TESTING_IN_PRODUCTION,
    ],
    members: [
      {
        id: "1",
        name: "Emerson Philips",
        status: "active",
        role: "Owner",
      },
      {
        id: "2",
        name: "Phillip Kenter",
        status: "active",
        role: "Developer",
      }
    ],
    createdAt: "2024-02-14 12:00:00",
    storageUsage: 30,
    teams: [
      {
        id: "1",
        name: "TechMate",
        icon: {
          letter: "T",
          backgroundColor: "#B3E8E9",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
      {
        id: "2",
        name: "Tasty Tech",
        icon: {
          letter: "T",
          backgroundColor: "#C5D8F8",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
      {
        id: "3",
        name: "Smart Style",
        icon: {
          letter: "T",
          backgroundColor: "#D8CEFA",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
    ],
  },
];
