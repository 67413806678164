import CustomTooltip from "app/components/Tooltip";
import React from "react";
import dropdownIcon from 'assets/svgs/ic_caret_down.svg'

export default function FilterButton({
    filter,
    setFilter
}: {
    filter: boolean,
    setFilter: (filter: boolean) => void
}) {
    return (
        <CustomTooltip text="Show filter" position="bottom">
            <button onClick={() => setFilter(!filter)} className="w-[24px] h-[24px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none">
                {
                    filter ?
                        <img src={dropdownIcon} alt="dropupIcon" className="size-[24px] transform rotate-180 transition-transform duration-200" />
                        :
                        <img src={dropdownIcon} alt="dropdownIcon" className="size-[24px] transition-transform duration-200" />
                }
            </button>
        </CustomTooltip>
    )
}