import React, { useCallback, useMemo, useState } from 'react'
import Button from 'app/components/Button';
import leftArrow from 'assets/svgs/ic_left_caret.svg';
import rightArrow from 'assets/svgs/ic_right_caret.svg';
import Progress from 'app/components/Progress';
import SingleSelect from 'app/components/SingleSelect';
import MultiSelect from 'app/components/MultiSelect';
import { useNavigate } from 'react-router-dom';
import { selectSetupForm } from 'app/containers/OnBoarding/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/OnBoarding/redux/slice';
import { companySizes, referalSources, usedToolsList } from 'app/containers/OnBoarding/data';
import Logo from 'app/components/Icons/Logo';

export default function Setup() {
    const [currentStage, setCurrentStage] = useState(0);
    const { companySize, referalSource, usedTools, isLoading } = useSelector(selectSetupForm);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const STAGES = useMemo(() => {
        return [
            {
                options: companySizes,
                title: 'How large is your company?',
                type: 'single-select',
                className: "max-w-[650px] mb-[56px]",
                value: companySize.value,
                onChange: (option: string | string[]) => {
                    dispatch(actions.setSetupFormValue({name: 'companySize', value: option}))
                }
            },
            {
                options: referalSources,
                title: 'How did you hear about us?',
                type: 'single-select',
                className: "container mx-auto mb-[56px]",
                value: referalSource.value,
                onChange: (option: string | string[]) => {
                    dispatch(actions.setSetupFormValue({name: 'referalSource', value: option}))
                }
            },
            {
                options: usedToolsList,
                title: "Do you use any of these tools?",
                type: 'multi-select',
                className: "container mx-auto mb-[56px]",
                value: usedTools.value,
                onChange: (option: string | string[]) => {
                    dispatch(actions.setSetupFormValue({name: 'usedTools', value: option}))
                }
            },
            {
                title: 'Would you like to create your first team?',
                options: [],
                type: 'button',
                className: "",
                value: '',
                onChange: (option: string | string[]) => {}
            }
        ]
    }, [companySize?.value, referalSource?.value, usedTools?.value, dispatch])

    const currentStageData = STAGES[currentStage];

    const handleNext = () => {
        if(currentStage === STAGES.length - 1) {
            navigate('/app/home');
            return;
        }
        setCurrentStage(currentStage + 1);
    }

    const handleBack = () => {
        if(currentStage === 0) {
            return;
        }
        setCurrentStage(currentStage - 1);
    }

    const isNextDisabled = useCallback(() => {
        if(currentStage === 0 && !companySize.value) {
            return true;
        }
        if(currentStage === 1 && !referalSource.value) {
            return true;
        }
        if(currentStage === 2 && usedTools.value?.length === 0) {
            return true;
        }
        return false;
    }, [currentStage, companySize.value, referalSource.value, usedTools.value])

    const handleSave = () => {
        dispatch(actions.saveSetupRequest({
            callback: () => navigate('/onboarding/welcome?callback=/app/home')
        }))
    }

    const handleCreateTeam = () => {
        dispatch(actions.saveSetupRequest({
            callback: () => navigate('/onboarding/team')
        }))
    }

    return (
        <div className="px-8 container max-w-screen-xl mx-auto flex flex-col items-center min-h-[70vh]">
            <div className="flex flex-col items-center">
                <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                    <Logo size={72}  />
                    <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">{STAGES[currentStage].title}</h1>
                </div>
            </div>

            {
                currentStageData.type === 'single-select' && (
                    <SingleSelect 
                        options={currentStageData.options} 
                        selected={currentStageData.value}
                        onChange={currentStageData.onChange}
                        className={currentStageData.className}
                    />
                )
            }

            {
                currentStageData.type === 'multi-select' && (
                    <MultiSelect 
                        options={currentStageData.options} 
                        selected={currentStageData.value}
                        onChange={currentStageData.onChange}
                        className={currentStageData.className}
                    />
                )
            }

            {
                currentStageData.type === 'button' && (
                    <Button
                        onClick={handleCreateTeam}
                        className="w-[390px] h-[56px]"
                        disabled={isLoading}
                        disabledClassName='bg-secondary'
                    >
                        Create team
                    </Button>
                )
            }

            <div className="absolute bottom-0 left-0 w-full py-8">
                <div className="container max-w-screen-xl mx-auto gap-[50px] flex flex-col px-8">
                    <Progress progress={(currentStage + 1) * 100 / STAGES.length} />
                    <div className="flex flex-row items-center justify-between">
                        <Button
                            disabled={currentStage === 0}
                            disabledClassName='opacity-50'
                            onClick={handleBack}
                            isLoading={isLoading}
                            className="border w-[110px] h-[52px] border-secondary bg-white text-secondary flex items-center justify-center gap-[6px]"
                        >
                            <span><img src={leftArrow} sizes='24px' alt="Back" /></span>
                            <span>Back</span>
                        </Button>
                        {
                            currentStage === STAGES.length - 1 ? (
                                <Button
                                    onClick={handleSave}
                                    isLoading={isLoading}
                                    className="border w-[110px] h-[52px] flex items-center justify-center gap-[6px]"
                                >
                                    <span>Finish</span>
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleNext}
                                    disabled={isNextDisabled()}
                                    disabledClassName='opacity-50'
                                    className="border w-[110px] h-[52px] border-secondary bg-white text-secondary flex items-center justify-center gap-[6px]"
                                >
                                    <span>Next</span>
                                    <span><img src={rightArrow} sizes='24px' alt="Next" /></span>
                                </Button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
