import CheckIcon from "app/components/Icons/CheckIcon";
import { CloseIcon } from "app/components/Icons/CloseIcon";
import { UndoIcon } from "app/components/Icons/UndoIcon";
import React from "react";
import { toast } from "react-toastify";

interface ToastComponentProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
  undoCallback?: () => void;
}

const Toast = ({
  message,
  type,
  onClose,
  undoCallback,
}: ToastComponentProps) => {
  return (
    <div className="flex flex-row gap-[6px]">
      <div className="flex justify-center flex-shrink-0 relative">
        <div
          className={`w-[16px] h-[16px] rounded-full ${
            type === "success" ? "bg-[#309957]" : "bg-danger"
          }`}
        />
        <div className="border-2 border-white absolute top-0 left-0 w-[16px] h-[16px] rounded-full" />
        <div className="absolute top-0 left-0 w-[16px] h-[16px] flex items-center justify-center">
          {type === "success" ? (
            <CheckIcon size={10} color="#fff" />
          ) : (
            <CloseIcon size="10" color="#fff" />
          )}
        </div>
      </div>
      <div className="flex flex-grow">
        <p
          className={`text-wrap w-[209px] font-normal ${
            type === "error" ? "text-danger" : ""
          }`}
        >
          {message}
        </p>
      </div>
      {undoCallback && (
        <button
          onClick={undoCallback}
          className="flex h-fit flex-row gap-[4px] items-center flex-shrink-0"
        >
          <UndoIcon />
          <strong className="text-primary mt-[2px] font-medium">Undo</strong>
        </button>
      )}
      <button
        className="h-[18px] flex items-center justify-center min-w-[18px] mt-[2px]"
        onClick={onClose}
      >
        <CloseIcon size="16" />
      </button>
    </div>
  );
};

const toastService = (() => {
  const success = (message: string, undoCallback?: () => void) => {
    toast.success(({ closeToast }) => (
      <Toast message={message} type="success" onClose={closeToast} />
    ));
  };

  const error = (message: string, undoCallback?: () => void) => {
    toast.error(({ closeToast }) => (
      <Toast message={message} type="error" onClose={closeToast} />
    ));
  };

  return {
    error,
    success,
  };
})();

export default toastService;
