import React from 'react'

export default function StarIcon({ color = '#79808A', size = 18 }: { color?: string, size?: number }) {
    return (
        <svg width={size} height={size - 1} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 1000001461">
                <path id="Vector" d="M18.9323 6.77481C18.8501 6.52593 18.6953 6.30631 18.487 6.1433C18.2788 5.98028 18.0263 5.88108 17.7611 5.85803L12.7247 5.43132L10.7505 0.817431C10.6477 0.575333 10.4743 0.368534 10.2522 0.223075C10.0301 0.077616 9.7691 0 9.50218 0C9.23526 0 8.9743 0.077616 8.75217 0.223075C8.53004 0.368534 8.35667 0.575333 8.25388 0.817431L6.28561 5.43132L1.24323 5.86053C0.976978 5.88252 0.723259 5.98128 0.513889 6.14441C0.304519 6.30755 0.148818 6.52781 0.0663109 6.77757C-0.0161957 7.02732 -0.0218351 7.29546 0.0501 7.54836C0.122035 7.80125 0.268342 8.02765 0.470675 8.19915L4.29613 11.4845L3.1496 16.3635C3.08897 16.6188 3.10627 16.886 3.19933 17.1317C3.29239 17.3774 3.45709 17.5907 3.67283 17.7449C3.88856 17.8991 4.14577 17.9874 4.41229 17.9988C4.67881 18.0101 4.94281 17.944 5.1713 17.8086L9.49624 15.225L13.8305 17.8086C14.059 17.944 14.323 18.0101 14.5895 17.9988C14.856 17.9874 15.1133 17.8991 15.329 17.7449C15.5447 17.5907 15.7094 17.3774 15.8025 17.1317C15.8955 16.886 15.9128 16.6188 15.8522 16.3635L14.7065 11.4795L18.5311 8.19915C18.7334 8.02705 18.8795 7.80002 18.9508 7.54659C19.0222 7.29315 19.0157 7.02464 18.9323 6.77481ZM17.6416 7.19153L13.817 10.4719C13.6308 10.631 13.4923 10.837 13.4164 11.0677C13.3405 11.2985 13.3301 11.5451 13.3862 11.7812L14.5352 16.6685L10.2043 14.0848C9.99305 13.9584 9.75042 13.8915 9.50303 13.8915C9.25563 13.8915 9.01301 13.9584 8.80171 14.0848L4.47676 16.6685L5.61821 11.7846C5.67431 11.5485 5.66384 11.3018 5.58793 11.0711C5.51202 10.8404 5.37355 10.6344 5.18741 10.4753L1.3611 7.19653C1.36079 7.19404 1.36079 7.19152 1.3611 7.18903L6.40179 6.76064C6.64789 6.73932 6.88339 6.65237 7.0829 6.50917C7.28241 6.36597 7.43836 6.17195 7.53391 5.94804L9.50218 1.33999L11.4696 5.94804C11.5652 6.17195 11.7211 6.36597 11.9206 6.50917C12.1201 6.65237 12.3556 6.73932 12.6017 6.76064L17.6433 7.18903V7.19486L17.6416 7.19153Z" fill={color}/>
            </g>
        </svg>
    )
}