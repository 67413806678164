import React, { useState } from 'react'
import Layout from '../../components/Layout'
import TicketGroup from '../../components/TicketGroup'
import BacklogIcon from 'app/components/Icons/BacklogIcon'
import { HEADERS, TICKETS } from './data'
import Row from './components/Row'
import Planning from './components/Planning'
import PaneSwitchOffIcon from 'app/components/Icons/PaneSwitchOffIcon'

export default function Backlog() {
    const [showPlanning, setShowPlanning] = useState(false)
    return (
        <Layout>
            <div className="ml-[38px] flex gap-[16px] h-[calc(100vh-200px)]">         
                <div className="flex-grow min-w-[410px] md:min-w-[600px] lg:min-w-[800px]">
                    <TicketGroup 
                        icon={<BacklogIcon />}
                        title="Backlog"
                        count={TICKETS.length}
                        headers={HEADERS}
                        color="#79808A"
                    >
                        {
                            TICKETS.map((ticket) => (
                                <Row key={ticket.id} ticket={ticket} />
                            ))
                        }
                    </TicketGroup>
                </div>
                {
                    !showPlanning && (
                        <button className="size-[20px] mt-[3px]" onClick={() => setShowPlanning(true)}>
                            <PaneSwitchOffIcon />
                        </button>
                    )
                }
                {
                    showPlanning &&
                    <Planning onClose={() => setShowPlanning(false)} />
                }
            </div>
        </Layout>
    )
}
