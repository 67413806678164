import StarIcon from 'app/components/Icons/StarIcon'
import StarIconActive from 'app/components/Icons/StarIconActive'
import CustomTooltip from 'app/components/Tooltip'
import clsx from 'clsx'
import React from 'react'

interface FavoriteButtonProps {
    className?: string
    onClick?: () => void
    isFavorite?: boolean
}

export default function FavoriteButton({className, onClick, isFavorite}: FavoriteButtonProps) {
    return (
        <CustomTooltip text={isFavorite ? "Unfavorite" : "Favorite"}>
            <button onClick={onClick} className={clsx("flex items-center justify-center size-[20px] min-w-[20px] bg-white border border-[#E9ECF2] hover:bg-[#ECEEF2] rounded-[2px]", className)}>
                {isFavorite ? <StarIconActive size={14} /> : <StarIcon size={14} color="#79808A" />}
            </button>
        </CustomTooltip>
    )
}