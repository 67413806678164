import React, { useEffect } from 'react'
import { TICKET_HEADERS } from 'app/containers/Features/containers/Boards/data'
import StatusIcon from 'app/components/Icons/StatusIcon'
import TicketGroup from 'app/containers/Features/containers/Boards/components/TicketGroup'
import Row from '../../../Home/containers/components/Row';
import { useDispatch } from 'react-redux';
import { selectTicketGroups } from '../../redux/selector';
import { useSelector } from 'react-redux';
import { actions } from '../../redux/slice';

export default function List() {
    const dispatch = useDispatch();
    const groups = useSelector(selectTicketGroups);
    
    useEffect(() => {
        dispatch(actions.fetchTicketGroups());
    }, [dispatch]);

    const handleDrop = (ticketId: string, targetStatus: string) => {
        // Find the source status by looking through all groups
        const sourceGroup = groups.find(group => 
            group.tickets.some(ticket => ticket.id === ticketId)
        );
        
        if (!sourceGroup || sourceGroup.status.name === targetStatus) return;

        dispatch(actions.updateTicketStatus({
            ticketId,
            sourceStatus: sourceGroup.status.name,
            targetStatus
        }));
    };

    return (
        <div className="pl-[38px]">         
            {
                groups.map((group) => (
                    <TicketGroup 
                        key={group.status.name}
                        icon={<StatusIcon color={group.status.color} />}
                        title={group.status.name}
                        count={group.tickets.length}
                        headers={TICKET_HEADERS}
                        color={group.status.color}
                        onDrop={(ticketId) => handleDrop(ticketId, group.status.name)}
                    >
                        {
                            group.tickets.map((ticket) => (
                                <Row key={ticket.id} ticket={ticket} />
                            ))
                        }
                    </TicketGroup>
                ))
            }
        </div>
    )
}