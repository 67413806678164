import Pill from 'app/components/Pill'
import React from 'react'
import { getTicketIcon } from '../../Home/containers/helpers'
import { BacklogTicket } from '../types'
import EpicIcon from 'app/components/Icons/EpicIcon'
import { useQueryState } from 'nuqs'

export default function Row({
    ticket
}: {
    readonly ticket: BacklogTicket
}) {
    const [, setTid] = useQueryState('tid');
    return (
        <tr onClick={()=>setTid(ticket.id)} className="h-[36px] border-b border-[#E9ECF2] cursor-pointer group hover:bg-[#E7F1FF]">
            <td className="max-w-[428px]">
                <div className="flex flex-row items-center gap-[4px] relative p-[4px]">
                    {/* <button className={clsx("absolute z-10 opacity-100 left-[-42px] hover:border hover:border-secondary hover:rounded-[1.5px]")}>
                        hello
                    </button> */}
                    <div className="min-w-[20px]">
                        <img src={getTicketIcon(ticket?.type)} alt="task-icon" className="size-[20px]" />
                    </div>
                    <span className="text-sm leading-[18px] font-normal text-black">
                        {ticket?.id}
                    </span>
                    <div className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap">
                        {ticket?.name}
                    </div>
                    <div className="flex flex-row items-center gap-[4px]">
                        {
                            ticket?.labels.map((label) => (
                                <Pill key={label.name} backgroundColor={label.backgroundColor} textColor={label.color} name={label.name} />
                            ))
                        }
                    </div>
                </div>
            </td>
            <td className="p-[4px] min-w-[200px]">
                <div className="flex flex-row items-center gap-[4px]">
                    <div className="min-w-[20px]">
                        <EpicIcon color={ticket?.epic?.color} />
                    </div>
                    <span style={{ color: ticket?.epic?.color }} className="text-sm leading-[21px] font-normal">
                        {ticket?.epic?.name}
                    </span>
                </div>
            </td>
            <td className="p-[4px] min-w-[120px]">
                <span className="text-sm leading-[21px] font-normal text-[#253858]">
                    {ticket?.release}
                </span>
            </td>
            <td className="p-[4px] min-w-[210px]">
                <span className="text-sm leading-[21px] font-normal text-[#253858]">
                    {ticket?.sprint}
                </span>
            </td>
        </tr>
    )
}
