import React from 'react';
import { TableHeader } from './types';
import SortIcon from '../Icons/SortIcon';
import { useSearchParams } from 'react-router-dom';
import CustomTooltip from '../Tooltip';

interface TableProps {
  readonly headers: TableHeader[];
  readonly emptyText?: string;
  readonly children?: React.ReactNode;
  readonly headerClassName?: string;
  readonly tableClassName?: string;
}

export default function Table({ 
  headers,
  emptyText="There are no any boards",
  children=null,
  headerClassName,
  tableClassName
}: TableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSortBy = searchParams.get('sortBy');
  const currentSortDir = searchParams.get('sortDir');

  const handleSort = (headerId: string) => {
    let newSortDir = 'asc';
    
    // If already sorting by this column, toggle direction
    if (currentSortBy === headerId) {
      newSortDir = currentSortDir === 'asc' ? 'desc' : 'asc';
    }

    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set('sortBy', headerId);
      newParams.set('sortDir', newSortDir);
      return newParams;
    });
  };

  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full overflow-visible">
        <table className={`w-full max-w-full bg-white border border-[#E9ECF2] overflow-visible ${tableClassName} flex-shrink-0`}>
          <thead>
            <tr className={`bg-[#F4F5F7] h-[52px] ${headerClassName}`}>
              {headers.map((header) => (
                <th key={header.id} style={{width: header?.width, textAlign: header?.align ?? "left"}} className={`px-4 pr-2 py-[12px] text-sm leading-[21px] font-normal text-secondary hover:bg-[#ECEEF2] group ${header?.className}`}>
                  <div className="flex flex-row items-center gap-[2px]">
                    <span>{header.name}</span>
                    {header.sortable && (
                      <button 
                        onClick={() => handleSort(header.id)}
                        className={`flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity ${currentSortBy === header.id ? 'opacity-100' : ''}`}
                      >
                        <CustomTooltip text="Sort">
                          <div className={`transition-transform ${currentSortBy === header.id && currentSortDir === 'desc' ? 'rotate-180' : ''}`}>
                            <SortIcon />
                          </div>
                        </CustomTooltip>

                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-visible">
            {!children ? (
              <tr>
                <td colSpan={headers.length} className="text-sm leading-[21px] font-normal text-secondary text-center h-[52px]">
                  {emptyText}
                </td>
              </tr>
            ) : (
              children
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
