import React from "react";

interface PlayCircleIconProps {
  size: number;
  color?: string;
}

export default function PlayCircleIcon({
  size = 20,
  color = "#79808A",
}: PlayCircleIconProps) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
      >
        <path
          d="M8.3335 12.1938V7.80559C8.33359 7.75068 8.34897 7.69682 8.37801 7.64963C8.40706 7.60244 8.44871 7.56367 8.49859 7.53738C8.54848 7.51108 8.60476 7.49824 8.66155 7.50019C8.71834 7.50214 8.77354 7.51882 8.82136 7.54847L12.3543 9.74196C12.399 9.76965 12.4358 9.80777 12.4613 9.85281C12.4868 9.89785 12.5002 9.94836 12.5002 9.99969C12.5002 10.051 12.4868 10.1015 12.4613 10.1466C12.4358 10.1916 12.399 10.2297 12.3543 10.2574L8.82136 12.4515C8.77354 12.4812 8.71834 12.4979 8.66155 12.4998C8.60476 12.5018 8.54848 12.4889 8.49859 12.4626C8.44871 12.4363 8.40706 12.3976 8.37801 12.3504C8.34897 12.3032 8.33359 12.2493 8.3335 12.1944V12.1938Z"
          fill="#00B884"
        />
        <path
          d="M2.5 10C2.5 5.85795 5.85795 2.5 10 2.5C14.142 2.5 17.5 5.85795 17.5 10C17.5 14.142 14.142 17.5 10 17.5C5.85795 17.5 2.5 14.142 2.5 10ZM10 3.52273C8.28212 3.52273 6.6346 4.20515 5.41988 5.41988C4.20515 6.6346 3.52273 8.28212 3.52273 10C3.52273 11.7179 4.20515 13.3654 5.41988 14.5801C6.6346 15.7948 8.28212 16.4773 10 16.4773C11.7179 16.4773 13.3654 15.7948 14.5801 14.5801C15.7948 13.3654 16.4773 11.7179 16.4773 10C16.4773 8.28212 15.7948 6.6346 14.5801 5.41988C13.3654 4.20515 11.7179 3.52273 10 3.52273Z"
          fill="#00B884"
        />
      </svg>
    </div>
  );
}
