import React, { useCallback, useRef } from 'react'
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import Button from 'app/components/Button';
import rightArrow from 'assets/svgs/ic_right_arrow_primary.svg';
import { selectLoginForm } from '../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { validateLoginForm } from '../helpers';
import EmailIcon from 'app/components/Icons/EmailIcon';
import PasswordIcon from 'app/components/Icons/PasswordIcon';
import Logo from 'app/components/Icons/Logo';
import { GoogleIcon } from 'app/components/Icons/GoogleIcon';

export default function Login() {
    const dispatch = useDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const loginForm = useSelector(selectLoginForm);

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault(); 
        const errors = validateLoginForm(loginForm);
        if (errors.length > 0) {
            dispatch(actions.setLoginFormErrors(errors));
            return;
        }
        dispatch(actions.loginRequest({
            email: loginForm.email.value,
            password: loginForm.password.value
        }));
    }

    const isDisabled = useCallback(() => {
        if(!!loginForm.email.error || !!loginForm.password.error) {
            return true
        };
        if(loginForm.email.value === '' || loginForm.password.value === '') {
            return true;
        };
        return false;
    }, [loginForm.email.error, loginForm.password.error, loginForm.email.value, loginForm.password.value]);
    
    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72} />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Welcome back</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full min-w-[390px] mb-[56px]'>
                <Input 
                    id="email" 
                    label="Email" 
                    type="email" 
                    name="email"
                    placeholder="Enter email address" 
                    icon={<EmailIcon />}
                    inputRef={emailInputRef}
                    defaultValue={loginForm.email.value}
                    error={loginForm.email.error}
                    disabled={loginForm.isLoading}
                    onChange={(e) => {
                        dispatch(actions.setLoginFormValues({name: e.target.name, value: e.target.value}));
                    }}
                />
                <div>
                <Input 
                    id="password" 
                    label="Password" 
                    type="password" 
                    name="password"
                    placeholder="Password" 
                    icon={<PasswordIcon />}
                    inputRef={passwordInputRef}
                    defaultValue={loginForm.password.value}
                    error={loginForm.password.error}
                    disabled={loginForm.isLoading}
                    onChange={(e) => {
                        dispatch(actions.setLoginFormValues({name: e.target.name, value: e.target.value}));
                    }}
                />
                <div className='flex justify-end mt-[6px]'>
                    <Link to="/auth/forgot-password" className="text-right text-sm text-primary">Forgot password?</Link>
                </div>
                </div>
                <div className="flex flex-col gap-[10px] items-center justify-between">
                    <Button 
                        disabled={isDisabled()}
                        onClick={handleSubmit} 
                        isLoading={loginForm.isLoading}
                        className='w-full h-[56px]'
                    >
                        Log in
                    </Button>
                    <span className='text-base leading-[24px] text-secondary'>or</span>
                    <Button className='w-full h-[56px] flex items-center justify-center bg-white border border-secondary text-secondary'>
                        <div className='flex items-center gap-[10px]'>
                            <GoogleIcon size={24} />
                            <span className='text-base font-medium leading-[22.4px] text-secondary'>Sign up with Google</span>
                        </div>
                    </Button>
                </div>
            </form>
            <div className='flex flex-col items-center gap-[16px]'>
                <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>Don’t have an account? <Link to="/auth/signup" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Sign up <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
            </div>
        </div>
    )
}
