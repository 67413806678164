import { put, takeLatest } from "redux-saga/effects";
import { teamActions } from "./slice";
import { TEAM_DATA } from "../data";
import toastService from "utils/toast";

// Fetch team data
function* setTeamDataSaga() {
  try {
    yield put(teamActions.fetchTeamDataSuccess(TEAM_DATA));
  } catch (error) {
    console.error("Error setting static data", error);
    yield put(teamActions.fetchTeamDataFailure("Failed to set static data"));
  }
}

// Add a new project to a team
function* addProjectToTeamSaga(
  action: ReturnType<typeof teamActions.addProjectToTeam>
) {
  try {
    yield put(
      teamActions.addProjectToTeamSuccess(
        "Project has been successfully renamed"
      )
    );

    toastService.success("Project has been successfully renamed");
  } catch (error: any) {
    console.error("Error renaming project", error.message || error);
    yield put(teamActions.fetchTeamDataFailure("Failed to rename project"));
    toastService.error("Failed to rename the project");
  }
}
// Add a member to a project
function* addMemberToProjectSaga(
  action: ReturnType<typeof teamActions.addMemberToProject>
) {
  try {
    const { teamId, projectId, member } = action.payload;
    const team = TEAM_DATA.find((team) => team.id === teamId);
    const project = team?.projects.find((project) => project.id === projectId);
    if (project) {
      if (project?.members?.length) {
        project.members.push(member);
      } else {
        project.members = [member];
      }
      yield put(teamActions.fetchTeamDataSuccess(TEAM_DATA));
    }
  } catch (error) {
    console.error("Error adding member to project", error);
    yield put(
      teamActions.fetchTeamDataFailure("Failed to add member to project")
    );
  }
}

// Rename a project
function* renameProjectSaga(
  action: ReturnType<typeof teamActions.renameProject>
) {
  try {
    // API call
    yield put(teamActions.renameProjectSuccess("Renamed successfully"));

    toastService.success("Project has been successfully renamed");
  } catch (error: any) {
    console.error("Error renaming project", error.message || error);
    yield put(teamActions.fetchTeamDataFailure("Failed to rename project"));
    toastService.error("Failed to rename the project");
  }
}

// Toggle project visibility
function* toggleHideSaga(action: ReturnType<typeof teamActions.toggleHide>) {
  const { toggle } = action.payload;
  try {
    yield put(teamActions.toggleHideSuccess("Project successfully hiden"));

    toastService.success(
      `Project has been successfully ${toggle ? "hidden" : "unhidden"}`
    );
  } catch (error: any) {
    console.error("Error toggleHide project", error.message || error);
    yield put(
      teamActions.fetchTeamDataFailure(
        `Failed to ${toggle ? "hide" : "unhide"} project`
      )
    );
    toastService.error("Failed to toogleHide the project");
  }
}

// Toggle project archive status
function* toggleArchiveSaga(
  action: ReturnType<typeof teamActions.toggleArchive>
) {
  const { toggle } = action.payload;
  try {
    yield put(teamActions.toggleArchiveSuccess("Archive toggle successfull"));

    toastService.success(
      `Project has been successfully ${toggle ? "archived" : "unarchived"}`
    );
  } catch (error: any) {
    console.error("Error toggleHide project", error.message || error);
    yield put(
      teamActions.fetchTeamDataFailure(
        `Failed to ${toggle ? "archive" : "unarchive"} project`
      )
    );
    toastService.error("Failed to toggleArchive the project");
  }
}

export default function* TeamSaga() {
  yield takeLatest(teamActions.fetchTeamDataRequest.type, setTeamDataSaga);
  yield takeLatest(teamActions.addProjectToTeam.type, addProjectToTeamSaga);
  yield takeLatest(teamActions.addMemberToProject.type, addMemberToProjectSaga);
  yield takeLatest(teamActions.renameProject.type, renameProjectSaga);
  yield takeLatest(teamActions.toggleHide.type, toggleHideSaga);
  yield takeLatest(teamActions.toggleArchive.type, toggleArchiveSaga);
}
