import AnchorIcon from 'app/components/Icons/AnchorIcon'
import CustomTooltip from 'app/components/Tooltip'
import clsx from 'clsx'
import React from 'react'
import toastService from 'utils/toast'

interface CopyButtonProps {
    copyText: string
    className?: string
}

export default function CopyButton({copyText, className}: CopyButtonProps) {
    const handleCopy = () => {
        navigator.clipboard.writeText(copyText)
        toastService.success(`${copyText} link has been successfully copied.`)
    }
    return (
        <CustomTooltip text="Copy link">
            <button onClick={handleCopy} className={clsx("flex items-center justify-center size-[20px] min-w-[20px] bg-[#ECEEF2] rounded-[2px]", className)}>
                <AnchorIcon size={16} color="#79808A" />
            </button>
        </CustomTooltip>
    )
}