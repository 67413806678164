import React from "react";

interface ActivityIconProps {
  size: number;
  color?: string;
}

export default function ActivityIcon({
  size = 20,
  color = "#79808A",
}: ActivityIconProps) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 17"
        fill={color}
      >
        <path
          d="M12.4423 1.68553L12.4424 1.68532C12.7157 1.12817 13.2399 0.729424 13.86 0.603992C14.5089 0.47518 15.1891 0.687284 15.6645 1.16957L15.6647 1.16974C16.1034 1.61165 16.2998 2.12641 16.2619 2.7101C16.1896 3.78929 15.3423 4.59266 14.2785 4.60027L12.4423 1.68553ZM12.4423 1.68553C12.361 1.84969 12.3093 1.97994 12.2776 2.11433C12.2458 2.24869 12.2332 2.39014 12.2332 2.57729L12.2332 2.57774M12.4423 1.68553L12.2332 2.57774M12.2332 2.57774C12.2294 3.14026 12.4266 3.62084 12.822 4.01185L12.822 4.01185M12.2332 2.57774L12.822 4.01185M12.822 4.01185L12.8222 4.01208M12.822 4.01185L12.8222 4.01208M12.8222 4.01208C13.2215 4.4108 13.699 4.60407 14.2784 4.60027L12.8222 4.01208ZM14.5291 1.73699L14.5291 1.73698L14.5302 1.73731C14.879 1.84481 15.1505 2.23742 15.1505 2.62434C15.1505 2.74051 15.0994 2.88446 15.0298 3.01083C14.96 3.13756 14.8643 3.25959 14.7634 3.32672L14.7633 3.32681C14.5718 3.45359 14.3682 3.50887 14.1697 3.48899C13.9712 3.46912 13.7837 3.37472 13.6232 3.2125C13.1755 2.76941 13.2809 2.07581 13.8408 1.79627C13.9599 1.73682 14.0673 1.70215 14.1792 1.69293C14.2909 1.68372 14.4025 1.70028 14.5291 1.73699Z"
          fill="#5A8DF1"
          stroke="#5A8DF1"
          stroke-width="0.133333"
        />
        <path
          d="M5.32671 1.35945C4.45668 1.40276 3.82852 1.5002 3.31588 1.66983C2.18592 2.04517 1.22563 3.00519 0.850181 4.13483C0.572202 4.97935 0.5 5.92132 0.5 8.82662C0.5 11.7319 0.572202 12.6739 0.850181 13.5184C1.21841 14.6228 2.1787 15.5972 3.26534 15.9654C4.12455 16.2577 5.09206 16.3335 8.00903 16.3335C11.3556 16.3335 12.2473 16.2361 13.1895 15.7777C14.1029 15.3302 14.8502 14.4712 15.1715 13.504C15.2256 13.3452 15.2978 13.0456 15.3375 12.8327C15.4819 12.0315 15.4964 11.6164 15.5 8.90963C15.5 6.33636 15.5 6.30388 15.4242 6.20644C15.2834 6.01515 15.1679 5.95741 14.9404 5.95741C14.713 5.95741 14.5975 6.01515 14.4567 6.20644C14.3809 6.30388 14.3809 6.35441 14.3592 8.94572C14.3448 10.5518 14.3195 11.7355 14.2942 11.9665C14.1643 13.1503 13.9368 13.6989 13.3448 14.2619C13.0235 14.5723 12.7274 14.7527 12.3339 14.8826C11.5722 15.1353 10.5433 15.193 7.43141 15.1678C6.18592 15.1569 5.05235 15.1317 4.85018 15.11C3.70578 14.9837 3.13899 14.7491 2.59025 14.1789C1.97292 13.5437 1.77798 12.9337 1.69134 11.4071C1.6444 10.5662 1.6444 7.08705 1.69134 6.24614C1.77798 4.71589 1.97292 4.11317 2.59025 3.47437C3.1426 2.90413 3.70939 2.66954 4.85018 2.54323C5.09928 2.51796 6.22563 2.4927 7.88989 2.47826C10.4819 2.45661 10.5325 2.45661 10.63 2.38082C10.8213 2.24006 10.8791 2.12457 10.8791 1.8972C10.8791 1.66983 10.8213 1.55434 10.63 1.41359C10.5325 1.3378 10.4964 1.3378 8.19675 1.33419C6.91155 1.33058 5.61913 1.3414 5.32671 1.35945Z"
          fill="#5A8DF1"
        />
        <path
          d="M12.1812 5.54932C12.1213 5.57587 12.0316 5.64038 11.9867 5.6897C11.9418 5.73902 11.6165 6.37265 11.2649 7.09734C10.5619 8.54671 10.5058 8.62639 10.1281 8.74021C9.89621 8.8123 9.82889 8.8123 9.58581 8.73642C9.30907 8.65295 9.12583 8.47083 8.92015 8.06106C8.52374 7.28325 7.84685 6.85072 7.02038 6.84692C6.39585 6.84313 5.9583 7.01766 5.51328 7.44261L5.2328 7.71579L4.55217 9.11963C4.1782 9.88985 3.86033 10.5728 3.84537 10.6297C3.75935 11.0053 4.15576 11.3999 4.54843 11.324C4.81769 11.2709 4.88127 11.1723 5.56937 9.75705C6.13033 8.60362 6.23878 8.40253 6.37341 8.28491C6.7586 7.95103 7.31955 7.97758 7.65987 8.34941C7.72344 8.41771 7.85059 8.6188 7.94034 8.79713C8.41155 9.73049 9.42501 10.1896 10.4272 9.9202C10.7825 9.82535 11.048 9.6622 11.351 9.36246L11.6426 9.07031L12.3195 7.67026C12.8955 6.48268 13.0002 6.24744 13.0002 6.09947C13.0002 5.67452 12.5589 5.37858 12.1812 5.54932Z"
          fill="#5A8DF1"
        />
      </svg>
    </div>
  );
}
