import IconText from 'app/components/IconText'
import React from 'react'
import PlusIcon from "app/components/Icons/PlusIcon";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Filters } from './FilterBar';

export default function TicketsFilter({ options, selectedFilters, onSelectFilter }: { options: typeof Filters, selectedFilters: typeof Filters, onSelectFilter: (filter: typeof Filters) => void }) {
    const [open, setOpen] = React.useState(false);

    const optionsWithoutSelected = options.filter((filter) => !selectedFilters.some(selectedFilter => selectedFilter.name === filter.name));

    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
            <DropdownMenu.Trigger asChild>
                <button className={`focus:outline-none outline-none rounded-[4px] hover:bg-[#E9ECF2] px-[6px] h-[32px] flex items-center ${open ? "border border-secondary" : ""}`}>
                    <IconText
                        icon={<PlusIcon color="#79808A" size={20} />}
                        text="Filter"
                        className="gap-[6px]"
                        textClassName="text-[14px] font-medium text-[#79808A] leading-[21px] mt-[2px]"
                    />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content 
                    align="start"
                    className="min-w-[224px] bg-white rounded-[4px] border border-[#E9ECF2] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] py-[10px] z-50"
                    sideOffset={5}
                >
                    <div className="flex items-center gap-2 pl-4 pr-[8px] py-[12px] text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase outline-none border-b border-[#E9ECF2]">
                        Filters
                    </div>

                    <div className="max-h-[220px] overflow-y-auto custom-scrollbar">
                        {optionsWithoutSelected.map((filter) => (
                            <DropdownMenu.Item
                                key={filter.name}
                                className="flex items-center gap-2 pl-4 pr-[8px] py-[12px] text-sm leading-[21px] mt-[2px] text-secondary outline-none cursor-pointer rounded hover:bg-[#E9ECF2] hover:rounded-none focus:bg-[#E9ECF2]"
                                onClick={() => onSelectFilter([...selectedFilters, filter])}
                            >
                                <span className="flex items-center justify-center size-[20px]">
                                    {filter.icon}
                                </span>
                                {filter.name}
                            </DropdownMenu.Item>
                        ))}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}