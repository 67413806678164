import React from "react";
import close from "assets/svgs/ic_close.svg";
import { Dialog, DialogContent } from "../Dialog";

interface DialogProps {
  onClose: () => void;
  onSubmit?: () => void;
  submitText?: string;
  title: string;
  children: React.ReactNode;
  contentClassName?: string;
  modalClassName?: string;
  renderModalHeader?: React.ReactNode;
  renderModalFooter?: React.ReactNode;
  footerRender?: boolean;
  isFullmodel?: boolean;
  width?: string;
  height?: string;
  optionalFooterClass?: string;
}

export default function Modal({
  onClose,
  title = "Title",
  children,
  contentClassName = "",
  modalClassName = "",
  onSubmit,
  submitText = "Archive",
  renderModalHeader,
  renderModalFooter,
  footerRender = true,
  isFullmodel = false,
  width = "w-[500px]",
  height = "h-[auto]",
  optionalFooterClass = ""
}: DialogProps) {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      
      {/* for making backside of modal darker */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)] z-40" />
      <DialogContent
       width={width}
       height={height}
       className={`!p-0 border-none shadow-lg bg-white rounded-lg ${modalClassName}`}
      >
        <div className={isFullmodel ? "flex flex-col" : ""}>
          {/* Header */}
          {renderModalHeader ? (
            renderModalHeader
          ) : (
            <div className="flex justify-between items-center bg-[#F4F5F7] border-b border-gray-300 px-6 py-4">
              <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
              <button onClick={onClose} className="p-1">
                <img src={close} alt="close" className="w-6 h-6" />
              </button>
            </div>
          )}

          {/* Content */}
          <div
            className={`flex-grow p-4 overflow-y-auto ${contentClassName}`}
            style={{ maxHeight: "78vh" }}
          >
            {children}
          </div>
        </div>
        {/* Footer */}
        {footerRender && (
          <div
            className={
              optionalFooterClass.length
                ? optionalFooterClass
                : "flex justify-end items-center gap-4 bg-[#F4F5F7] border-t border-gray-300 px-6 py-4"
            }
          >
            {renderModalFooter ? (
              renderModalFooter
            ) : (
              <>
                <button
                  onClick={onClose}
                  type="button"
                  className="bg-[transparent] text-secondary border border-secondary rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center"
                >
                  Cancel
                </button>
                <button
                  onClick={onSubmit}
                  type="button"
                  className="bg-primary text-white rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center"
                >
                  {submitText}
                </button>
              </>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
