import React from 'react'
import { selectBoards, selectFavorites, selectQaBoards, selectRecents } from '../../../redux/selector'
import { useSelector } from 'react-redux'
import boardIcon from 'assets/svgs/ic_boards_ico.svg'
import IconText from 'app/components/IconText'
import CopyButton from '../../../components/CopyButton'

export default function Cards() {
    const boards = useSelector(selectBoards)
    const recents = useSelector(selectRecents)
    const favorites = useSelector(selectFavorites)
    const qaBoards = useSelector(selectQaBoards)


    const items = [
        {
            title: "Recent",
            boards: boards.filter(board => recents.includes(board.id))
        },
        {
            title: "Favorites",
            boards: boards.filter(board => favorites.includes(board.id))
        },
        {
            title: "QA boards",
            boards: boards.filter(board => qaBoards.includes(board.id))
        }
    ]
    return (
        <div className="grid grid-cols-3 gap-4 mb-4">
            {
                items.map((item) => (
                    <div key={item?.title} className="flex flex-col py-6 bg-white rounded-[4px] border-[1px] border-[#E9ECF2]">
                        <div className="px-6">
                            <h5 className="font-medium text-black leading-[24px]">{item?.title}</h5>
                        </div>
                        {
                            item?.boards.length > 0 ? (
                                <div className="w-full flex flex-col h-[160px] mt-[12px] overflow-y-auto px-[10px]">
                                    {
                                        item?.boards.map((board) => (
                                            <div key={board?.id} className="group flex items-center justify-between flex-row gap-[4px] py-2 hover:bg-[#F4F5F7] px-[14px] rounded-[4px]">
                                                <IconText 
                                                    text={board?.boardName}
                                                    icon={<img src={boardIcon} alt="board" className="size-[20px]" />}
                                                    className="text-secondary leading-[21px] text-sm"
                                                />
                                                <CopyButton
                                                    copyText={board?.boardName}
                                                    className="opacity-0 group-hover:opacity-100"
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className="w-full flex flex-col items-center justify-center h-[160px]">
                                    <p className="text-secondary leading-[21px] text-center text-sm">There are no any boards</p>
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}
