import React from 'react';

export function GoogleIcon({
    size = 25,
}: {
    size?: number;
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
            <path d="M9.52595 3.82556C7.5662 4.50542 5.8761 5.79581 4.70392 7.5072C3.53175 9.21859 2.93927 11.2608 3.01351 13.3338C3.08775 15.4068 3.82481 17.4013 5.11641 19.0245C6.40802 20.6476 8.18609 21.8138 10.1895 22.3517C11.8136 22.7708 13.5153 22.7892 15.1482 22.4054C16.6274 22.0731 17.9949 21.3624 19.117 20.3428C20.2847 19.2492 21.1324 17.8581 21.5687 16.3188C22.043 14.645 22.1274 12.8847 21.8154 11.1732H12.6949V14.9566H17.9769C17.8713 15.56 17.6451 16.1359 17.3118 16.6499C16.9784 17.1638 16.5448 17.6053 16.0369 17.9477C15.3919 18.3744 14.6649 18.6615 13.9024 18.7905C13.1376 18.9327 12.3532 18.9327 11.5885 18.7905C10.8134 18.6303 10.0802 18.3104 9.43554 17.8512C8.3999 17.1181 7.62227 16.0766 7.21363 14.8754C6.79808 13.6516 6.79808 12.3249 7.21363 11.1012C7.50451 10.2434 7.98537 9.46238 8.62033 8.81644C9.34696 8.06367 10.2669 7.52558 11.2792 7.26122C12.2915 6.99686 13.3571 7.01643 14.359 7.3178C15.1417 7.55806 15.8574 7.97784 16.4491 8.54368C17.0447 7.95117 17.6393 7.35713 18.2328 6.76155C18.5393 6.44129 18.8733 6.13635 19.1752 5.80843C18.272 4.9679 17.2118 4.31386 16.0553 3.88379C13.9494 3.11913 11.6452 3.09858 9.52595 3.82556Z" fill="white"/>
            <path d="M9.52595 3.82557C11.645 3.0981 13.9492 3.11811 16.0553 3.88227C17.212 4.31526 18.2717 4.97245 19.1737 5.8161C18.8672 6.14403 18.5439 6.4505 18.2313 6.76923C17.6367 7.36276 17.0427 7.95425 16.4491 8.54369C15.8574 7.97785 15.1417 7.55807 14.359 7.31781C13.3574 7.01539 12.2919 6.99468 11.2793 7.25796C10.2667 7.52124 9.34622 8.05834 8.61879 8.81032C7.98384 9.45627 7.50297 10.2373 7.21209 11.0951L4.03552 8.63564C5.17254 6.38087 7.14122 4.65615 9.52595 3.82557Z" fill="#E33629"/>
            <path d="M3.19272 11.0722C3.36346 10.226 3.64692 9.40656 4.03552 8.63574L7.21209 11.1013C6.79654 12.325 6.79654 13.6517 7.21209 14.8755C6.15374 15.6927 5.09489 16.5141 4.03552 17.3395C3.0627 15.4031 2.76601 13.1968 3.19272 11.0722Z" fill="#F8BD00"/>
            <path d="M12.6948 11.1716H21.8154C22.1274 12.8832 22.043 14.6434 21.5687 16.3173C21.1324 17.8565 20.2847 19.2477 19.1169 20.3412C18.0918 19.5414 17.0621 18.7476 16.0369 17.9477C16.5452 17.6049 16.979 17.163 17.3123 16.6485C17.6457 16.134 17.8717 15.5574 17.9769 14.9535H12.6948C12.6933 13.6939 12.6948 12.4328 12.6948 11.1716Z" fill="#587DBD"/>
            <path d="M4.03398 17.3395C5.09335 16.5223 6.15221 15.7009 7.21055 14.8755C7.62001 16.0772 8.39875 17.1187 9.43553 17.8513C10.0822 18.3084 10.817 18.6257 11.5931 18.783C12.3578 18.9252 13.1422 18.9252 13.9069 18.783C14.6694 18.6539 15.3965 18.3669 16.0415 17.9402C17.0667 18.7401 18.0964 19.5338 19.1215 20.3337C17.9997 21.3539 16.6321 22.0651 15.1527 22.3978C13.5199 22.7817 11.8182 22.7633 10.194 22.3442C8.90948 22.0012 7.7096 21.3966 6.66963 20.5682C5.56888 19.6943 4.66984 18.5929 4.03398 17.3395Z" fill="#319F43"/>
        </svg>
    )
}