import NavMenu from "app/components/NavMenu";
import Table from "app/components/Table";
import React, { useMemo } from "react";
import Rows from "./Rows";
import { TableHeader } from "app/components/Table/types";
import { useQueryState } from "nuqs";
import { useSearchParams } from "react-router-dom";

const headers: TableHeader[] = [
  {
    name: "Board name",
    id: "boardName",
    align: "left",
    width: "200px",
    sortable: true,
  },
  {
    name: "Last viewed",
    id: "lastViewed",
    align: "left",
  },
  {
    name: "Last updated",
    id: "lastUpdated",
    align: "left",
  },
  {
    name: "Owner",
    id: "owner",
    align: "left",
  },
  {
    name: "Date created",
    id: "dateCreated",
    align: "left",
  },
];

export default function List() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "all";
  const options = useMemo(() => {
    return [
      {
        title: "all",
        icon: null,
        link: "/app/1/1/boards?type=all",
        isActive: type === "all",
      },
      {
        title: "my boards",
        icon: null,
        link: "/app/1/1/boards?type=my-boards",
        isActive: type === "my-boards",
      },
    ];
  }, [type]);
  return (
    <div className="flex flex-col gap-[10px] overflow-visible">
      <NavMenu options={options} />
      <div className="overflow-visible">
        <Table headers={headers}>
          <Rows />
        </Table>
      </div>
    </div>
  );
}
