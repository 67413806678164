import React from 'react'

export default function PaneSwitchOffIcon({ color='#253858', size=20 }: { color?: string, size?: number }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_18368_1090730" fill="white">
            <path d="M2 2H18V18H2V2Z"/>
            </mask>
            <path d="M2 2H18V18H2V2Z" fill="white"/>
            <path d="M2 2V0.5H0.5V2H2ZM18 2H19.5V0.5H18V2ZM18 18V19.5H19.5V18H18ZM2 18H0.5V19.5H2V18ZM2 3.5H18V0.5H2V3.5ZM16.5 2V18H19.5V2H16.5ZM18 16.5H2V19.5H18V16.5ZM3.5 18V2H0.5V18H3.5Z" fill={color} mask="url(#path-1-inside-1_18368_1090730)"/>
            <rect x="10" y="3" width="1.5" height="14" fill={color}/>
        </svg>

    )
}