/**
 * Boards selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.TicketGroupsState || initialState;

export const selectTicketGroups = createSelector(
    [selectDomain],
    (TicketGroupsState) => TicketGroupsState.groups,
);

export const selectIsLoading = createSelector(
    [selectDomain],
    (TicketGroupsState) => TicketGroupsState.isLoading,
);