import React from 'react'

interface UserGroupIconProps {
    color?: string
    size?: string
}

export default function UserGroupIcon({ color = '#79808A', size = '20' }: UserGroupIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M17.5 16.6641C17.5 15.2124 16.1083 13.9782 14.1667 13.5199M12.5 16.6641C12.5 14.8224 10.2617 13.3307 7.5 13.3307C4.73833 13.3307 2.5 14.8224 2.5 16.6641M12.5 10.8307C13.3841 10.8307 14.2319 10.4795 14.857 9.85442C15.4821 9.2293 15.8333 8.38145 15.8333 7.4974C15.8333 6.61334 15.4821 5.76549 14.857 5.14037C14.2319 4.51525 13.3841 4.16406 12.5 4.16406M7.5 10.8307C6.61594 10.8307 5.7681 10.4795 5.14298 9.85442C4.51786 9.2293 4.16667 8.38145 4.16667 7.4974C4.16667 6.61334 4.51786 5.76549 5.14298 5.14037C5.7681 4.51525 6.61594 4.16406 7.5 4.16406C8.38405 4.16406 9.2319 4.51525 9.85702 5.14037C10.4821 5.76549 10.8333 6.61334 10.8333 7.4974C10.8333 8.38145 10.4821 9.2293 9.85702 9.85442C9.2319 10.4795 8.38405 10.8307 7.5 10.8307Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}