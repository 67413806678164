import React from "react";

export default function ReleaseManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
    >
      <path
        d="M18.4383 0.212526C18.0563 0.41267 17.899 0.690647 17.899 1.15765C17.899 1.48011 18.1237 1.74697 19.8091 3.42595C20.8653 4.47115 21.7192 5.36068 21.7192 5.40516C21.7192 5.43851 21.6405 5.44963 21.5506 5.41628C21.4608 5.38292 20.6855 5.40516 19.8203 5.47187C14.989 5.84992 12.2025 7.75129 11.2025 11.3872C11.0564 11.8876 11.0003 12.5214 11.0003 13.5444C10.989 16.0684 11.3373 17.0469 12.2699 17.0469C13.0901 17.0469 13.5283 16.4131 13.3036 15.5458C13.2362 15.2789 13.1463 14.6118 13.1126 14.0781C12.8991 10.9314 14.5395 8.74089 17.7305 7.91808C18.5395 7.71793 21.6518 7.46219 21.4945 7.61786C21.4608 7.65122 20.7304 8.28501 19.8653 9.04111C17.9777 10.6645 17.899 10.7535 17.899 11.176C17.899 11.8654 18.2923 12.2545 18.9777 12.2657C19.4271 12.2657 19.5619 12.1656 22.7192 9.40804L25.1894 7.25648C25.6273 6.87507 25.6493 6.20211 25.2373 5.79289L22.8765 3.44819C21.1574 1.74697 19.6181 0.27924 19.4496 0.190289C19.0675 -0.00985718 18.8204 0.00126266 18.4383 0.212526Z"
        fill="#FF7979"
      />
      <path
        d="M3.11923 3.15141C1.97944 3.43315 1.18543 4.02226 0.890873 4.80346C0.801227 5.04679 0.75 7.96671 0.75 13.6529V22.1309L1.08297 22.7968C1.26227 23.1554 1.69769 23.7061 2.04347 24.0263C3.17046 25.038 2.65819 24.9996 13.4798 24.9996C24.3015 24.9996 23.6995 25.0508 24.5448 24.0263C25.3132 23.1042 25.3388 22.8352 25.3388 16.8033C25.3388 11.4885 25.326 11.3221 25.0827 11.0019C24.5576 10.3359 23.3153 10.4896 23.0336 11.2452C22.9568 11.4373 22.9055 13.7809 22.9055 16.8161C22.9055 20.1971 22.8543 22.1565 22.7775 22.323C22.6494 22.5663 22.4061 22.5663 13.3518 22.5663H4.05412L3.61869 22.1309L3.18327 21.6955V13.6273V5.57187L3.77237 5.48222C4.10535 5.4438 6.75633 5.40538 9.66344 5.40538H14.9398L15.2728 5.00837C15.8363 4.35523 15.6057 3.38193 14.8245 3.11299C14.2226 2.90808 3.97728 2.93369 3.11923 3.15141Z"
        fill="#FF7979"
      />
    </svg>
  );
}
