import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IProject,
  IMember,
  ITeamInitial,
  ProjectStatus,
  IComponent,
} from "../types";
import toastService from "utils/toast";

export const initialState: ITeamInitial = {
  loading: false,
  error: null,
  teams: [],
  selectedTeam: null,
  selectedProject: null,
};

function getTeamIndex(state: ITeamInitial): number {
  return state.teams.findIndex((t) => t.id === state.selectedTeam?.id);
}

function getProjectIndex(state: ITeamInitial, teamIndex: number): number {
  return state.teams[teamIndex].projects.findIndex(
    (p) => p.id === state.selectedProject?.id
  );
}

const TeamSlice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    fetchTeamDataRequest: (state) => {
      state.loading = true;
    },
    fetchTeamDataSuccess: (state, action) => {
      state.loading = false;
      state.teams = action.payload;
      state.selectedTeam = action.payload[0];
    },
    fetchTeamDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Fetch all teams
    getTeams: (state) => {
      return state;
    },

    // Get a single team
    getTeamById: (state, action: PayloadAction<string | undefined>) => {
      const teamId = action.payload;
      state.selectedTeam = teamId
        ? state.teams.find((team) => team.id === teamId) || null
        : state.teams[0] || null;
    },

    // Add new projects and members
    addProjectToTeam: (state, action: PayloadAction<IProject>) => {
      const newProject = action.payload;
      const selectedTeamId = state.selectedTeam?.id;
      if (!selectedTeamId) throw new Error("Team not selected");

      const teamIndex = state.teams.findIndex(
        (team) => team.id === selectedTeamId
      );

      state.teams[teamIndex].projects.push(newProject);
      state.selectedTeam = state.teams[teamIndex];
    },

    addProjectToTeamSuccess: (state, action: PayloadAction<string>) => {},

    addMemberToProject: (
      state,
      action: PayloadAction<{
        teamId: string;
        projectId: string;
        member: IMember;
      }>
    ) => {},

    removeMemberFromProject: (state, action: PayloadAction<IMember["id"]>) => {
      try {
        const memberId = action.payload;

        const teamIndex = state.teams.findIndex(
          (t) => t.id === state.selectedTeam?.id
        );

        if (teamIndex === -1) throw new Error("No team selected");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (p) => p.id === state.selectedProject?.id
        );

        if (projectIndex === -1) throw new Error("No project selected");

        const removedMember = state.teams[teamIndex].projects[
          projectIndex
        ].members?.find((m) => m.id === memberId);

        state.teams[teamIndex].projects[projectIndex].members = state.teams[
          teamIndex
        ].projects[projectIndex].members?.filter((m) => m.id !== memberId);

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        toastService.success(
          `Member ${removedMember?.name} has been successfully removed from the project.`
        );
      } catch (error) {
        console.log("Error while removing member", error);
      }
    },

    addMembersToProject: (state, action: PayloadAction<IMember[]>) => {
      try {
        const members = action.payload;

        const teamIndex = state.teams.findIndex(
          (team) => team.id === state.selectedTeam?.id
        );
        if (teamIndex === -1) throw new Error("No team found");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (project) => project.id === state.selectedProject?.id
        );
        if (projectIndex === -1) throw new Error("No project found");

        const existingMembers =
          state.teams[teamIndex].projects[projectIndex].members || [];

        const newMembers = members.filter(
          (newMember) =>
            !existingMembers.some(
              (existingMember) => existingMember.id === newMember.id
            )
        );

        if (newMembers.length > 0) {
          state.teams[teamIndex].projects[projectIndex].members = [
            ...existingMembers,
            ...newMembers,
          ];
        }

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        const memberName =
          members.length === 1 ? members[0].name + "has" : "members have";

        toastService.success(
          `New ${memberName} been successfully added to the project.`
        );
      } catch (error) {
        console.error("Error while adding project members", error);
      }
    },

    // Rename a project (saga implemented)
    renameProject: (
      state,
      action: PayloadAction<{
        projectId: string;
        newName: string;
      }>
    ) => {
      const { projectId, newName } = action.payload;
      const currentTeamId = state.selectedTeam?.id;

      if (!currentTeamId) {
        console.error("Team not selected");
        return;
      }

      const teamIndex = state.teams.findIndex(
        (team) => team.id === currentTeamId
      );
      if (teamIndex === -1) {
        console.error("Team not found");
        return;
      }

      const projectIndex = state.teams[teamIndex].projects.findIndex(
        (pro) => pro.id === projectId
      );
      if (projectIndex === -1) {
        console.error("Project not found");
        return;
      }

      state.teams[teamIndex] = {
        ...state.teams[teamIndex],
        projects: state.teams[teamIndex].projects.map((project, index) =>
          index === projectIndex ? { ...project, name: newName } : project
        ),
      };

      state.selectedTeam = state.teams[teamIndex];
    },

    renameProjectSuccess: (state, action: PayloadAction<string>) => {
      console.log("Project renamed successfully in state");
    },

    // Toggle project visibility (saga implemented)
    toggleHide: (
      state,
      action: PayloadAction<{
        projectId: string;
        toggle: boolean;
      }>
    ) => {
      const { projectId, toggle } = action.payload;
      const currentTeamId = state.selectedTeam?.id;

      if (!currentTeamId) {
        console.error("Team not selected");
        return;
      }

      const teamIndex = state.teams.findIndex(
        (team) => team.id === currentTeamId
      );
      if (teamIndex === -1) {
        console.error("Team not found");
        return;
      }

      const projectIndex = state.teams[teamIndex].projects.findIndex(
        (pro) => pro.id === projectId
      );
      if (projectIndex === -1) {
        console.error("Project not found");
        return;
      }

      state.teams[teamIndex] = {
        ...state.teams[teamIndex],
        projects: state.teams[teamIndex].projects.map((project, index) =>
          index === projectIndex ? { ...project, isHidden: toggle } : project
        ),
      };

      state.selectedTeam = state.teams[teamIndex];
    },

    toggleHideSuccess: (state, action: PayloadAction<string>) => {},

    toggleArchive: (
      state,
      action: PayloadAction<{
        projectId: string;
        toggle: boolean;
      }>
    ) => {
      const { projectId, toggle } = action.payload;
      const currentTeamId = state.selectedTeam?.id;

      if (!currentTeamId) {
        console.error("Team not selected");
        return;
      }

      const teamIndex = state.teams.findIndex(
        (team) => team.id === currentTeamId
      );
      if (teamIndex === -1) {
        console.error("Team not found");
        return;
      }

      const projectIndex = state.teams[teamIndex].projects.findIndex(
        (pro) => pro.id === projectId
      );
      if (projectIndex === -1) {
        console.error("Project not found");
        return;
      }

      state.teams[teamIndex] = {
        ...state.teams[teamIndex],
        projects: state.teams[teamIndex].projects.map((project, index) =>
          index === projectIndex ? { ...project, isArchived: toggle } : project
        ),
      };

      state.selectedTeam = state.teams[teamIndex];
    },

    toggleArchiveSuccess: (state, action: PayloadAction<string>) => {},

    updateProjectColor(state, action: PayloadAction<string>) {
      try {
        const newBgColor = action.payload;
        const { selectedProject, selectedTeam, teams } = state;

        if (!selectedProject || !selectedTeam) {
          throw new Error("No selected project or team");
        }

        const teamIndex = teams.findIndex(
          (team) => team.id === selectedTeam.id
        );
        if (teamIndex === -1) {
          throw new Error("Selected team not found");
        }

        const projectIndex = teams[teamIndex].projects.findIndex(
          (project) => project.id === selectedProject.id
        );
        if (projectIndex === -1) {
          throw new Error("Selected project not found in the team");
        }

        // Update project description immutably
        const updatedProjects = teams[teamIndex].projects.map(
          (project, index) =>
            index === projectIndex
              ? { ...project, icon: { ...project.icon, bgColor: newBgColor } }
              : project
        );

        // Update the team with updated projects
        const updatedTeam = {
          ...teams[teamIndex],
          projects: updatedProjects,
        };

        // Update state
        state.teams[teamIndex] = updatedTeam;
        state.selectedTeam = updatedTeam;
        state.selectedProject = updatedTeam.projects[projectIndex];

        toastService.success("Project avatar has been successfully changed.");
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
        } else {
          console.error("An unknown error occurred:", error);
        }
      }
    },

    updateSelectedProject: (state, action: PayloadAction<IProject["id"]>) => {
      const projectId = action.payload;
      const project = state.selectedTeam?.projects.find(
        (project) => project.id === projectId
      );
      if (project) state.selectedProject = project;
    },
    updateSelectedProjectDescription: (
      state,
      action: PayloadAction<string>
    ) => {
      try {
        const newDescription = action.payload;
        const { selectedProject, selectedTeam, teams } = state;

        if (!selectedProject || !selectedTeam) {
          throw new Error("No selected project or team");
        }

        const teamIndex = teams.findIndex(
          (team) => team.id === selectedTeam.id
        );
        if (teamIndex === -1) {
          throw new Error("Selected team not found");
        }

        const projectIndex = teams[teamIndex].projects.findIndex(
          (project) => project.id === selectedProject.id
        );
        if (projectIndex === -1) {
          throw new Error("Selected project not found in the team");
        }

        // Update project description immutably
        const updatedProjects = teams[teamIndex].projects.map(
          (project, index) =>
            index === projectIndex
              ? { ...project, description: newDescription }
              : project
        );

        // Update the team with updated projects
        const updatedTeam = {
          ...teams[teamIndex],
          projects: updatedProjects,
        };

        // Update state
        state.teams[teamIndex] = updatedTeam;
        state.selectedTeam = updatedTeam;
        state.selectedProject = updatedTeam.projects[projectIndex];

        toastService.success(
          "Project description has been successfully added."
        );
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
        } else {
          console.error("An unknown error occurred:", error);
        }
      }
    },

    updateProjectLead: (state, action: PayloadAction<IMember>) => {
      try {
        const newProjectLead = action.payload;

        const teamIndex = state.teams.findIndex(
          (t) => t.id === state.selectedTeam?.id
        );

        if (teamIndex === -1) throw new Error("Cannot find team");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (p) => p.id === state.selectedProject?.id
        );

        if (projectIndex === -1) throw new Error("Cannot find project");

        state.teams[teamIndex].projects[projectIndex].projectLead =
          newProjectLead;

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.selectedTeam.projects[projectIndex];

        toastService.success("Project lead has been successfully changed.");
      } catch (error) {
        console.error("Error updating project lead:", error);
      }
    },
    updateDefaultAssignee: (state, action: PayloadAction<IMember>) => {
      try {
        const newDefaultAssignee = action.payload;

        const teamIndex = state.teams.findIndex(
          (t) => t.id === state.selectedTeam?.id
        );

        if (teamIndex === -1) throw new Error("Cannot find team");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (p) => p.id === state.selectedProject?.id
        );

        if (projectIndex === -1) throw new Error("Cannot find project");

        state.teams[teamIndex].projects[projectIndex].defaultAssignee =
          newDefaultAssignee;

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.selectedTeam.projects[projectIndex];

        toastService.success(
          "Project default assignee has been successfully changed."
        );
      } catch (error) {
        console.error("Error updating default assignee:", error);
      }
    },
    updateProjectStatus: (state, action: PayloadAction<ProjectStatus>) => {
      try {
        const newStatus = action.payload;

        const teamIndex = state.teams.findIndex(
          (t) => t.id === state.selectedTeam?.id
        );

        if (teamIndex === -1) throw new Error("Cannot find team");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (p) => p.id === state.selectedProject?.id
        );

        if (projectIndex === -1) throw new Error("Cannot find project");

        state.teams[teamIndex].projects[projectIndex].status = newStatus;

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.selectedTeam.projects[projectIndex];
        toastService.success("Project status has been successfully changed.");
      } catch (error) {
        console.error("Error updating project status:", error);
      }
    },
    updateProjectDates: (
      state,
      action: PayloadAction<{
        dateType: "startDate" | "endDate";
        dateValue: IProject["startDate"] | IProject["endDate"];
      }>
    ) => {
      try {
        const { dateType, dateValue } = action.payload;

        const teamIndex = state.teams.findIndex(
          (t) => t.id === state.selectedTeam?.id
        );

        if (teamIndex === -1) throw new Error("Cannot find team");

        const projectIndex = state.teams[teamIndex].projects.findIndex(
          (p) => p.id === state.selectedProject?.id
        );

        if (projectIndex === -1) throw new Error("Cannot find project");

        state.teams[teamIndex].projects[projectIndex][dateType] = dateValue;

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.selectedTeam.projects[projectIndex];

        toastService.success(
          `${
            dateType === "startDate" ? "Start date" : "End date"
          } has been successfully changed.`
        );
      } catch (error) {
        console.error("Error updating project status:", error);
      }
    },
    toggleProjectFeatures: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      try {
        let toggleMsg = "";
        const { id, name } = action.payload;

        const teamIndex = getTeamIndex(state);
        if (teamIndex === -1) throw new Error("No team found");

        const projectIndex = getProjectIndex(state, teamIndex);
        if (projectIndex === -1) throw new Error("No project found");

        const project = state.teams[teamIndex].projects[projectIndex];
        const featureIndex = project.features.indexOf(id);

        if (featureIndex === -1) {
          // Add feature
          project.features.push(id);
          toggleMsg = "on";
        } else {
          // Remove feature
          project.features = project.features.filter(
            (feature) => feature !== id
          );
          toggleMsg = "off";
        }

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        toastService.success(
          `${name} feature has been successfully turned ${toggleMsg}.`
        );
      } catch (error) {
        console.error("Error while toggling feature:", error);
      }
    },
    addProjectComponent: (state, action: PayloadAction<IComponent>) => {
      try {
        const teamIndex = getTeamIndex(state);

        if (teamIndex === -1) throw new Error("Team not selected");

        const projectIndex = getProjectIndex(state, teamIndex);

        if (projectIndex === -1) throw new Error("Project not selected");

        state.teams[teamIndex].projects[projectIndex].components.push(
          action.payload
        );

        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        toastService.success(
          "New component has been successfully added to the project."
        );
      } catch (error) {
        console.log("Error while adding component", error);
      }
    },
    editProjectComponent: (state, action: PayloadAction<IComponent>) => {
      try {
        const component = action.payload;
        console.log({ component });

        const teamIndex = getTeamIndex(state);

        if (teamIndex === -1) throw new Error("Team not selected");

        const projectIndex = getProjectIndex(state, teamIndex);

        if (projectIndex === -1) throw new Error("Project not selected");

        const componentIndex = state.teams[teamIndex].projects[
          projectIndex
        ].components.findIndex((c) => c.id === component.id);

        if (componentIndex === -1) throw new Error("componen not found");

        const oldComponent =
          state.teams[teamIndex].projects[projectIndex].components[
            componentIndex
          ];
        state.teams[teamIndex].projects[projectIndex].components[
          componentIndex
        ] = { ...component, creationDate: oldComponent.creationDate };
        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        toastService.success("The component has been successfully changed.");
      } catch (error) {
        console.log("Error while editing component", error);
      }
    },
    archiveComponentById: (state, action: PayloadAction<string>) => {
      try {
        const componentId = action.payload;

        const teamIndex = getTeamIndex(state);

        if (teamIndex === -1) throw new Error("Team not selected");

        const projectIndex = getProjectIndex(state, teamIndex);

        if (projectIndex === -1) throw new Error("Project not selected");

        const componentIndex = state.teams[teamIndex].projects[
          projectIndex
        ].components.findIndex((c) => c.id === componentId);

        if (componentIndex === -1) throw new Error("componen not found");

        state.teams[teamIndex].projects[projectIndex].components[
          componentIndex
        ].isArchived = true;
        state.selectedTeam = state.teams[teamIndex];
        state.selectedProject = state.teams[teamIndex].projects[projectIndex];

        toastService.success("The component has been successfully archives.");
      } catch (error) {
        console.log("Error while editing component", error);
      }
    },
  },
});

export const {
  actions: teamActions,
  reducer: teamReducer,
  name: teamSlice,
} = TeamSlice;
