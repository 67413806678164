import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectBoards } from "app/containers/Features/containers/Boards/redux/selector";
import Row from "./Row";
import Archive from "./Archive";

export default function Rows() {
  const boards = useSelector(selectBoards);
  const [archiveModal, setArchiveModal] = useState(false);
  return (
    <>
      {boards.map((board) => (
        <Row
          key={board.id}
          board={board}
          onArchive={() => setArchiveModal(true)}
        />
      ))}
      {archiveModal && <Archive onClose={() => setArchiveModal(false)} />}
    </>
  );
}
  