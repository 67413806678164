import CalendarIcon from "app/components/Icons/CalendarIcon"
import CommentIcon from "app/components/Icons/CommentIcon"
import FlagIcon from "app/components/Icons/FlagIcon"
import PlusIcon from "app/components/Icons/PlusIcon"
import StatusIcon from "app/components/Icons/StatusIcon"
import TagIcon from "app/components/Icons/TagIcon"
import TicketsIcon from "app/components/Icons/TicketsIcon"
import UserIcon from "app/components/Icons/UserIcon"
import IconText from "app/components/IconText"
import Input from "app/components/Input"
import React from "react"
import StoryIcon from "app/components/Icons/StoryIcon"

export default function AddStory() {
    return (
        <>
            <div className="flex flex-row items-center px-[24px] py-4 border-b border-gray-100">
                <p className="text-xs leading-[21px] font-normal text-secondary"><span>Digital Innovators Collective</span> / <span className="text-success">TechMate</span></p>
            </div>
            <div className="flex flex-col px-[24px] py-4 pb-0">
                <Input 
                    icon={<></>}
                    placeholder='Enter story name'
                    id='story-name'
                    inputClassName='!h-[27px] border-none !px-0 rounded-none'
                    className='!text-lg !leading-[27px] rounded-none placeholder:!text-lg placeholder:!leading-[27px]'
                />
            </div>

            <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4 !max-h-[230px] overflow-y-auto">
                <div className="grid grid-cols-2 gap-[24px]">
                    <div className="flex flex-col gap-[16px]">
                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<TicketsIcon size={20} color="#79808A" />}
                                text="Ticket type:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={<StoryIcon size={20} color="#27AE60" />}
                                text="Story"
                                className="gap-[4px]"
                                textClassName="text-xs leading-[21px] font-normal text-black"
                            />
                        </div>

                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<StatusIcon size={20} color="#79808A" />}
                                text="Status:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={<></>}
                                text="-"
                                className="gap-[4px]"
                                textClassName="text-xs leading-[21px] font-normal"
                            />
                        </div>

                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<UserIcon size={20} color="#79808A" />}
                                text="Assignee:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={''}
                                text="-"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<CalendarIcon size={20} color="#79808A" />}
                                text="Due date:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={''}
                                text="-"
                            />
                        </div>

                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<FlagIcon size={20} color="#79808A" />}
                                text="Priority:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={''}
                                text="-"
                            />
                        </div>

                        <div className="flex flex-row items-center justify-between gap-[8px]">
                            <IconText 
                                icon={<TagIcon size={20} color="#79808A" />}
                                text="Tags:"
                                className="gap-[4px]"
                                textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                            />
                            <IconText 
                                icon={''}
                                text="-"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <Input
                        placeholder='Enter ticket description...'
                        id='task-description'
                        inputClassName='!h-[42px]'
                    />
                </div>
                <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-col gap-[4px] w-full">
                        <p className="text-xs leading-[21px] font-medium text-black">Custom fields</p>
                        <div className="flex flex-row items-center w-full">
                            <div className="w-[196px] border border-gray-100 border-r-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-[4px]">
                                <IconText
                                    icon={<CommentIcon color="#79808A" />}
                                    text="Comments*"
                                    className="gap-[4px]"
                                    textClassName="text-[12px] leading-[18px] font-medium text-secondary"
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    placeholder='Enter comments'
                                    id='task-description'
                                    inputClassName='!h-[42px] w-full rounded-l-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-4 px-[24px] pb-4 pb-0">
                <div className="flex flex-row justify-between items-center">
                    <p className="text-xs leading-[21px] font-medium text-black">Attachments</p>
                    <button className="focus:outline-none">
                        <PlusIcon size={24} color="#79808A" />
                    </button>
                </div>
                <p className="text-xs leading-[18px] font-normal text-secondary text-center cursor-pointer">
                    Drop your files here to <span className="underline">upload</span>
                </p>
            </div>
        </>
    )
}