import React from "react";

interface EyeIconProps {
  size?: number;
  color?: string;
  isClosed?: boolean;
}

export default function EyeIcon({
  size = 20,
  color = "#79808A",
  isClosed = false,
}: EyeIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 10C12.25 10.5967 12.0129 11.169 11.591 11.591C11.169 12.0129 10.5967 12.25 10 12.25C9.40326 12.25 8.83097 12.0129 8.40901 11.591C7.98705 11.169 7.75 10.5967 7.75 10C7.75 9.40326 7.98705 8.83097 8.40901 8.40901C8.83097 7.98705 9.40326 7.75 10 7.75C10.5967 7.75 11.169 7.98705 11.591 8.40901C12.0129 8.83097 12.25 9.40326 12.25 10Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 10C3.7 6.92725 6.502 4.75 10 4.75C13.498 4.75 16.3 6.92725 17.5 10C16.3 13.0728 13.498 15.25 10 15.25C6.502 15.25 3.7 13.0728 2.5 10Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      {isClosed && (
        <path
          d="M15 3.57812L5 16.4948"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />
      )}
    </svg>
  );
}
