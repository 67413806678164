import React from "react";

export default function PlayIcon({
    size = 20,
    color = '#00B884'
}: {
    readonly size?: number,
    readonly color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M8.33594 12.1938V7.80559C8.33603 7.75068 8.35141 7.69682 8.38045 7.64963C8.4095 7.60244 8.45115 7.56367 8.50103 7.53738C8.55092 7.51108 8.6072 7.49824 8.66399 7.50019C8.72078 7.50214 8.77598 7.51882 8.8238 7.54847L12.3567 9.74196C12.4014 9.76965 12.4383 9.80777 12.4637 9.85281C12.4892 9.89785 12.5026 9.94836 12.5026 9.99969C12.5026 10.051 12.4892 10.1015 12.4637 10.1466C12.4383 10.1916 12.4014 10.2297 12.3567 10.2574L8.8238 12.4515C8.77598 12.4812 8.72078 12.4979 8.66399 12.4998C8.6072 12.5018 8.55092 12.4889 8.50103 12.4626C8.45115 12.4363 8.4095 12.3976 8.38045 12.3504C8.35141 12.3032 8.33603 12.2493 8.33594 12.1944V12.1938Z" fill={color}/>
            <path d="M2.5 10C2.5 5.85795 5.85795 2.5 10 2.5C14.142 2.5 17.5 5.85795 17.5 10C17.5 14.142 14.142 17.5 10 17.5C5.85795 17.5 2.5 14.142 2.5 10ZM10 3.52273C8.28212 3.52273 6.6346 4.20515 5.41988 5.41988C4.20515 6.6346 3.52273 8.28212 3.52273 10C3.52273 11.7179 4.20515 13.3654 5.41988 14.5801C6.6346 15.7948 8.28212 16.4773 10 16.4773C11.7179 16.4773 13.3654 15.7948 14.5801 14.5801C15.7948 13.3654 16.4773 11.7179 16.4773 10C16.4773 8.28212 15.7948 6.6346 14.5801 5.41988C13.3654 4.20515 11.7179 3.52273 10 3.52273Z" fill={color}/>
        </svg>
    )
}