import ListIcon from 'app/components/Icons/ListIcon'
import { Board, BoardPermission } from '../../../types'
import React, { useState } from 'react'
import { BoardType } from '../../../types'
import KabanIcon from 'app/components/Icons/KabanIcon'
import CustomTooltip from 'app/components/Tooltip'
import UserGroupIcon from 'app/components/Icons/UserGroupIcon'
import PasswordIcon from 'app/components/Icons/PasswordIcon'
import { CogIcon } from 'app/components/Icons'
import IconText from 'app/components/IconText'
import EditButton from '../../../components/EditButton'
import CopyButton from '../../../components/CopyButton'
import FavoriteButton from '../../../components/FavoriteButton'
import Input from 'app/components/Input'

interface EditableRowItemProps {
    board: Board
}

const getBoardIcon = (type: BoardType) => {
    if (type === BoardType.LIST) {
        return <ListIcon color="#79808A" />
    }
    return <KabanIcon color="#79808A" />
}

const getPermissionIcon = (permission: BoardPermission) => {
    if (permission === BoardPermission.PUBLIC) {
        return (
            <CustomTooltip text="Public permission">
                <UserGroupIcon color="#79808A" />
            </CustomTooltip>
        )
    }
    if (permission === BoardPermission.PRIVATE) {
        return (
            <CustomTooltip text="Private permission">
                <PasswordIcon color="#79808A" size="20" />
            </CustomTooltip>
        )
    }
    return (
        <CustomTooltip text="Custom permission">
            <CogIcon color="#79808A" size={20} />
        </CustomTooltip>
    )
}

export default function EditableRowItem({board}: EditableRowItemProps) {
    const [isEditing, setIsEditing] = useState(false)
    return (
        <>
            {
                isEditing ? (
                    <Input
                        id="board-name"
                        name="boardName"
                        value={board.boardName}
                        onChange={() => {}}
                        icon={getBoardIcon(board.type)}
                        inputClassName="!rounded-none !p-2 h-full"
                    />
                ) : (
                    <IconText 
                        text={board.boardName}
                        icon={getBoardIcon(board.type)}
                        iconClassName="size-[20px]"
                        className="h-full min-h-[40px] gap-[6px]"
                        textClassName="text-sm leading-[21px] font-normal text-[#333E4D]"
                    />
                )
            }
            <div className="flex items-center justify-center">
                {getPermissionIcon(board.permission)}
            </div>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                <EditButton onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsEditing(isEditing => !isEditing)
                }} />
            </div>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                <CopyButton copyText={board.boardName} className="bg-white border border-[#E9ECF2] hover:bg-[#ECEEF2] rounded-[2px]" />
            </div>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                <FavoriteButton />
            </div>
        </>
    )
}