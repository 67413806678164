import React from "react";
import { Filters } from "./FilterBar";
import TicketsFilterDropdown from "./TicketsFilterDropdown";

interface SelectedFilterProps {
    options: typeof Filters;
    selectedOptions: string[];
    onSelectOption: (option: string[]) => void;
}

export default function SelectedFilter({ options, selectedOptions, onSelectOption }: SelectedFilterProps) {

    return (
        <>
            {
                options.map(option => (
                    <TicketsFilterDropdown key={option.name} option={option} selectedOptions={selectedOptions} onSelectOption={onSelectOption} />
                ))
            }
        </>
    );
}