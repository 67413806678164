import SearchIcon from "app/components/Icons/SearchIcon";
import Input from "app/components/Input";
import React, { useState } from "react";

export default function SearchButton({
    placeholder,
    value,
    onChange,
}: {
    placeholder?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const [open, setOpen] = useState(false);
    
    const handleBlur = () => {
        if (!value) {
            setOpen(false);
        }
    };
    
    return (
        <>
            {open ? (
                <Input
                    id="search"
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    icon={<SearchIcon size="20" color="#79808A" />}
                    containerClassName="h-fit bg-white rounded-[4px]"
                    inputClassName="!h-[32px] !py-[6px] w-[325px]"
                    innerContainerClassName="!gap-0"
                    onBlur={handleBlur}
                />
            ) : (
                <button 
                    onClick={() => setOpen(true)}
                    className="min-w-[32px] size-[32px] bg-white border border-[#E9ECF2] rounded-[4px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none"
                >
                    <SearchIcon size="20" color="#79808A" />
                </button>
            )}
        </>
    )
}