import React, { useEffect } from 'react'
import { useQueryState } from 'nuqs'
import Column from '../../../Home/components/Column';
import { selectTicketGroups } from '../../redux/selector';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { actions } from '../../redux/slice';

export default function Kaban() {
    const [, setTid] = useQueryState('tid');
    const dispatch = useDispatch();
    const groups = useSelector(selectTicketGroups);

    useEffect(() => {
        dispatch(actions.fetchTicketGroups());
    }, [dispatch]);

    const handleDragEnd = (ticketId: string, sourceStatus: string, targetStatus: string) => {
        if (sourceStatus === targetStatus) return;
        
        dispatch(actions.updateTicketStatus({
            ticketId,
            sourceStatus,
            targetStatus
        }));
        
        // Here you would also make an API call to update the backend
        // For now, we're just updating the Redux state
    };

    return (
        <div className="flex-shrink-0 w-full h-[calc(100vh-160px)] overflow-x-auto">    
            <div className="w-full max-w-[calc(100vw-76px)] h-full">
                <div className="grid grid-cols-5 gap-[16px] h-full min-w-[max-content]">
                    {
                        groups.map((group) => (
                            <div 
                                onClick={() => setTid(group.status.name)} 
                                key={group.status.name} 
                                className="w-[255px]"
                            >
                                <Column 
                                    group={group} 
                                    onDragEnd={handleDragEnd}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}