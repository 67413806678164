export enum PLAN_TYPE {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export interface FormElement {
  value: any;
  error: string;
}

export interface FormError {
  name: string;
  error: string;
}

export interface AuthorizedAccount {
  email: string;
  password: string;
}

export interface AppState {
  isAuthenticated: boolean;
  authorizedAccounts: AuthorizedAccount[];
  user: User | null;
  loading: boolean;
  error: string | null;
  drawerOpen: boolean;
  drawerPayload?: any;
  teamForm: TeamForm;
  teamFormActiveIndex: number;
  project: Project | null;
}

export type Project = {
  id: string;
  name: string;
  icon: {
    avatar: string;
    color: string;
    backgroundColor: string;
  };
  owner: Owner;
  plan: PLAN_TYPE;
  statuses: Statuses[];
  members: TeamMember[];
  createdAt: string;
  storageUsage: number;
};

export enum Statuses {
  TODO = "TO DO",
  IN_PROGRESS = "IN PROGRESS",
  DONE = "DONE",
  REOPENED = "REOPENED",
  IN_CODE_REVIEW = "IN CODE REVIEW",
  ABANDONED = "ABANDONED",
  IN_STAGING = "IN STAGING",
  TESTING_IN_PRODUCTION = "TESTING IN PRODUCTION",
}

export type Owner = {
  id: string;
  name: string;
  avatar: string;
  isOnline: boolean;
};

export type TeamForm = {
  name: FormElement;
  project: {
    name: FormElement;
    defaultCustomization: {
      agileDevelopmentAndSprints: boolean;
      epicsAndStories: boolean;
      releaseManagement: boolean;
    };
  };
  members: TeamMemberType[];
  plan: PLAN_TYPE;
  coupon: FormElement;
  isLoading: boolean;
};

export interface User {
  email: string;
  name?: string;
  companySize?: string;
  referalSource?: string;
  usedTools?: string[];
  team?: Team;
  initials: string;
  role: string;
  status: string;
  lastLogin: string;
  localTime: string;
  location: string;
  spokenLanguages: string[];
  creationDate: string;
  mobileNumber: string;
  members: TeamMember[];
}

export interface Team {
  name: string;
  members: TeamMember[];
  plan: PLAN_TYPE;
}

export interface TeamMember {
  status?: string;
  email?: string;
  localTime?: string;
  name?: string;
  role?: string;
  id?: string;
  team?: string;
  lastLogin?: string;
  location?: string;
  spokenLanguages?: string;
  mobileNumber?: string;
  creationDate?: string;
}

export type Option = {
  label: string;
  value: string;
  description?: string;
  icon?: React.ReactNode;
  searchText?: string;
  tick?: string;
  role?: string;
};

export type TeamMemberType = {
  name: FormElement;
  role: FormElement;
};

export type ContainerState = AppState;
