import React from "react";

interface VersionIconProps {
  size?: number;
}

export default function VersionIcon({ size = 20 }: VersionIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 8L7.99363 11L14 8M2 11L7.99363 14L14 11M2 5.11775L7.99963 8L14 5.11775L7.99963 2L2 5.11775Z"
        stroke="#79808A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
