import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import IconText from "app/components/IconText";
import { DropdownIcon } from "app/components/Icons";
import clsx from "clsx";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import CheckboxActiveIcon from "app/components/Icons/CheckboxActiveIcon";

interface TicketsFilterDropdownProps {
    option: {
        name: string;
        icon: React.ReactNode;
        options: string[];
    }
    selectedOptions: string[];
    onSelectOption: (option: string[]) => void;
}

export default function TicketsFilterDropdown({ option, selectedOptions, onSelectOption }: TicketsFilterDropdownProps) {
    const [open, setOpen] = useState(false);
    // TODO: Check if any of option.options is selected
    const isSelected = option.options.some(option => selectedOptions.includes(option));
    const clonedIcon = React.cloneElement(option.icon as React.ReactElement, { color: isSelected ? "#5A8DF1" : "#79808A" });
    const selectedText = option.options.filter(option => selectedOptions.includes(option)).join(", ");
    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
            <DropdownMenu.Trigger asChild key={option.name}>
                <button className={clsx("text-sm font-normal text-secondary h-[32px] max-w-[270px] flex flex-row items-center gap-[6px] justify-center bg-white border rounded-[54px] px-[10px] text-ellipsis whitespace-nowrap overflow-hidden", open ? "border-secondary" : isSelected ? "border-primary" : "border-[#E9ECF2]")}>
                    <IconText 
                        icon={clonedIcon} 
                        text={isSelected ? `${option.name}: ${selectedText}` : option.name}
                        className={clsx("text-sm font-medium mt-[2px] max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden", isSelected ? "text-primary" : "text-secondary")}
                    />
                    <DropdownIcon size={20} color={isSelected ? "#5A8DF1" : "#79808A"} />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content 
                    className="w-[250px] bg-white py-[10px] px-0"
                    align="start"
                >
                    {option.options.map((type) => (
                        <DropdownMenu.Item
                            key={type}
                            className="px-4 py-3 hover:bg-[#F4F5F7] hover:border-none focus:outline-none border-none cursor-pointer flex flex-row items-center gap-[10px]" 
                            onClick={(e) => {
                                e.preventDefault();
                                if (selectedOptions.includes(type)) {
                                    onSelectOption(selectedOptions.filter(o => o !== type));
                                } else {
                                    onSelectOption([...selectedOptions, type]);
                                }
                            }}
                        >
                            {
                                selectedOptions.includes(type) ? (
                                    <CheckboxActiveIcon />
                                ) : (
                                    <CheckboxIcon />
                                )
                            }
                            <span className="text-sm leading-[21px] text-secondary font-medium mt-[2px]">{type}</span>
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}