import React, { useState, useRef, useEffect } from "react";
import DropdownIcon from "../Icons/DropdownIcon";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { Option } from "app/types";
import Input from "../Input";
import SearchIcon from "../Icons/SearchIcon";
import CautionDangerIcon from "../Icons/CautionDangerIcon";

// Select Component
interface SelectProps<T> {
    options: T;
    value?: string;
    error?: string;
    label?: string;
    id?: string;
    containerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    activeColor?: string;
    icon?: React.ReactNode;
    placeholder?: string;
    onChange?: (value: string) => void;
    innerContainerClassName?: string;
    menuListItemClassName?: string;
    menuListItemLabelClassName?: string;
    allowSearch?: boolean;
    searchPlaceholder?: string;
    trigger?: React.ReactNode;
    searchValue?: string;
    handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    unPaddedRole?:boolean
}

export const Select: React.FC<SelectProps<Option[]>> = ({
    options,
    value,
    error,
    label,
    id,
    containerClassName,
    inputClassName,
    labelClassName,
    activeColor,
    icon,
    placeholder,
    onChange,
    innerContainerClassName,
    menuListItemClassName,
    menuListItemLabelClassName,
    allowSearch,
    searchPlaceholder,
    trigger,
    searchValue = "",
    handleSearchChange,
    unPaddedRole=false,
}) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dropdownWidth, setDropdownWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setDropdownWidth(containerRef.current.offsetWidth);
        }
    }, []);

    const clonedIcon =
        icon &&
        React.cloneElement(icon as React.ReactElement, {
            color: error
                ? "#EB5757"
                : open || value
                    ? activeColor
                    : "#BDC0C3",
        });

    return (
        <div ref={containerRef} className={`relative ${containerClassName}`}>
            <DropdownMenu open={open} onOpenChange={setOpen}>
                <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
                    {label && (
                        <label
                            htmlFor={id}
                            className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
                        >
                            {label}
                        </label>
                    )}
                    <DropdownMenuTrigger asChild>
                        {trigger || (
                            <div
                                className={`flex items-center gap-[8px] flex-row border rounded-[4px] py-[16px] px-[10px] h-[48px] ${inputClassName} ${open ? "border-[#79808A] !border-[1px]" : "border-[#E9ECF2]"
                                    } ${error && "border-danger"}`}
                            >
                                {icon && <div>{clonedIcon}</div>}
                                <button
                                    className="flex items-center justify-between text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px]"
                                >
                                    {value ? (
                                        <div className="flex flex-row items-center gap-[4px]">
                                            {options.find((option) => option.value === value)?.icon && (
                                                <div className="min-w-[20px] min-h-[20px]">
                                                    {options.find((option) => option.value === value)?.icon}
                                                </div>
                                            )}
                                            <span className="font-normal text-sm leading-[21px] text-black">
                                                {options.find((option) => option.value === value)?.label}
                                            </span>
                                        </div>
                                    ) : (
                                        <span className="font-normal text-sm leading-[21px] text-[#BDC0C3]">
                                            {placeholder}
                                        </span>
                                    )}
                                    <div
                                        className={`size-[16px] min-w-[16px] transition-transform duration-300 ease-in-out ${open ? "rotate-180" : ""
                                            }`}
                                    >
                                        <DropdownIcon size={16} />
                                    </div>
                                </button>
                            </div>
                        )}
                    </DropdownMenuTrigger>

                    <DropdownMenuContent
                        className="py-[10px] z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2] max-h-[320px] overflow-y-auto w-[300px]"
                        style={{ width: '100%' }} // Adjust the width here to fit content
                        sideOffset={5}
                    >
                        {allowSearch && (
                            <DropdownMenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                className="border-b border-[#E9ECF2] hover:outline-none"
                            >
                                <Input
                                    id="search"
                                    placeholder={searchPlaceholder}
                                    inputClassName="border-none"
                                    icon={<SearchIcon size="17" color="#79808A" />}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                            </DropdownMenuItem>
                        )}
                        {options.map((option) => (
                            <DropdownMenuItem
                                key={option.value}
                                onClick={() => {
                                    onChange?.(option.value);
                                    setOpen(false);
                                }}
                                className={`cursor-pointer hover:outline-none text-sm leading-[21px] font-normal text-secondary hover:bg-[#F4F5F7] px-[8px] pl-[16px] py-[10px] flex items-center gap-2 ${menuListItemClassName}`}
                            >
                                <div className="flex flex-1 items-start gap-2">
                                    {option.icon && (
                                        <div className="min-w-[20px] min-h-[20px]">{option.icon}</div>
                                    )}
                                    <div className="flex flex-col">
                                        <span
                                            className={`text-sm leading-[21px] font-normal text-black ${menuListItemLabelClassName}`}
                                        >
                                            {option.label}
                                        </span>
                                        {option.role && (
                                            <span className={`text-xs leading-[16px] font-normal text-[#79808A] ${unPaddedRole? "" : "ml-8"} mt-1`}>
                                                {option.role}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {option.tick && (
                                    <span className="text-[#5A8DF1] font-bold ml-auto">✓</span>
                                )}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </div>
                {error && (
                    <p className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px] flex items-center gap-[4px]">
                        <CautionDangerIcon />
                        <span className="mt-[2px]">{error}</span>
                    </p>
                )}
            </DropdownMenu>
        </div>
    );
};