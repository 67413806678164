import React from "react";

interface MinimizeIconProps {
    size?: number;
    color?: string;
}

export const MinimizeIcon = ({ size = 24, color = "#79808A" }: MinimizeIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12" fill="none">
            <path d="M0.798935 11.5825C0.694153 11.5836 0.590264 11.565 0.493947 11.5279C0.397631 11.4907 0.311019 11.4359 0.23968 11.3668C-0.0798935 11.0792 -0.0798935 10.6334 0.23968 10.3457L10.6258 1.01259C10.9454 0.724971 11.4407 0.724971 11.7603 1.01259C12.0799 1.3002 12.0799 1.74601 11.7603 2.03363L1.37417 11.3812C1.21438 11.525 1.00666 11.5969 0.814914 11.5969L0.798935 11.5825Z" fill={color}/>
            <path d="M7.56 11.5984H0.84C0.3696 11.5984 0 11.2816 0 10.8784V5.11844C0 4.71524 0.3696 4.39844 0.84 4.39844C1.3104 4.39844 1.68 4.71524 1.68 5.11844V10.1584H7.56C8.0304 10.1584 8.4 10.4752 8.4 10.8784C8.4 11.2816 8.0304 11.5984 7.56 11.5984Z" fill={color}/>
        </svg>
    )
}

export default MinimizeIcon;    