import React from "react";

interface DashBoardProps {
    size?: number;
    color?: string
}

 const DashBoard: React.FC<DashBoardProps> = ({size=20,color="#79808A"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.78571 14.2161L6.78571 5.71429M10 14.2696V10M13.2143 14.2857L13.2143 7.85714M4.64286 2.5L15.3571 2.5C15.9255 2.5 16.4705 2.72576 16.8724 3.12763C17.2742 3.52949 17.5 4.07454 17.5 4.64286V15.3571C17.5 15.9255 17.2742 16.4705 16.8724 16.8724C16.4705 17.2742 15.9255 17.5 15.3571 17.5H4.64286C4.07454 17.5 3.52949 17.2742 3.12763 16.8724C2.72576 16.4705 2.5 15.9255 2.5 15.3571L2.5 4.64286C2.5 4.07454 2.72576 3.52949 3.12763 3.12763C3.52949 2.72576 4.07454 2.5 4.64286 2.5Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export default DashBoard