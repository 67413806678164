import React from "react";

interface UndoIconProps {
    size?: string;
    color?: string;
}

export const UndoIcon = ({ size = '16', color = "#5A8DF1" }: UndoIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M5.50955 2.39844L2.39844 5.45726L5.50955 8.51608M7.99844 12.7984H9.8651C10.3554 12.7984 10.8408 12.7035 11.2938 12.519C11.7467 12.3346 12.1583 12.0642 12.505 11.7233C12.8516 11.3825 13.1266 10.9779 13.3143 10.5325C13.5019 10.0872 13.5984 9.60988 13.5984 9.12785C13.5984 8.64582 13.5019 8.16851 13.3143 7.72318C13.1266 7.27784 12.8516 6.8732 12.505 6.53235C12.1583 6.19151 11.7467 5.92113 11.2938 5.73667C10.8408 5.5522 10.3554 5.45726 9.8651 5.45726H3.02066" stroke={color} strokeWidth="1.5"/>
        </svg>
    )
}
