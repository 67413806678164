import React from 'react'

export default function OrderIcon({
    size = 20,
    color = '#79808A'
}: {
    size?: number,
    color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M10 11.8731L13.75 15.8307L17.5 11.8731M10 8.01858L6.25 4.16406M6.25 4.16406L2.5 8.01569M6.25 4.16406V15.7276M13.75 4.16406V15.7276" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}   