import { CloseIcon } from "app/components/Icons";
import Logo from "app/components/Icons/Logo";
import React, { useState } from "react";
import { BillCard } from "./BillCard";
import { PLANS } from "../types";
import PlusIcon from "app/components/Icons/PlusIcon";
import Button from "app/components/Button";
import { PRICES } from "../containers/Team/components/Monthly";
import PrimaryCheckIcon from "app/components/Icons/PrimaryCheckIcon";

import visa from 'assets/svgs/visa.svg';
import mastercard from 'assets/svgs/mastercard.svg';
import amex from 'assets/svgs/amex.svg';
import discover from 'assets/imgs/discover.png';
import maestro from 'assets/svgs/maestro.svg';
import AddCardModal from "./AddCardModal";

export const CARDS = [
    {
        img: visa,
        alt: 'visa',
        width: 48,
        height: 15,
        bg: "#3756A3"
    },
    {
        img: maestro,
        alt: 'maestro',
        width: 62,
        height: 37,
        bg: "#144478"
    },
    {
        img: mastercard,
        alt: 'mastercard',
        width: 52,
        height: 37,
        bg: "#144478"
    },
    {
        img: amex,
        alt: 'amex',
        width: 36.024,
        height: 23.999,
        bg: "#1577A7"
    },
    {
        img: discover,
        alt: 'discover',
        width: 50,
        height: 9,
        bg: "#F0F1F1"
    }
];

export const PaymentDialog = ({
    onProceed,
    onClose
}: {
    onProceed: () => void;
    onClose: () => void;
}) => {
    const [showAddCard, setShowAddCard] = useState(false);
    return (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-[#0000004D] z-10" role="dialog" aria-modal="true">
            <div className="relative p-8 border border-[#E9ECF2] rounded-[6px] bg-[#F4F5F7] shadow-xl transition-all w-full h-full max-w-[1376px] max-h-[92vh] flex flex-col">
                <button className="absolute top-6 right-6" onClick={onClose}>
                    <CloseIcon size="32" color="#79808A" />
                </button> 
                <div className="w-full flex flex-row items-center justify-center gap-2 mb-2">
                    <Logo size={45} />
                    <h1 className="text-[37.561px] font-bold text-black">Teambox</h1>
                </div>
                <div className="overflow-y-auto flex-1">
                    <div className="flex justify-center w-full">
                        <div className="w-full max-w-4xl p-8 bg-white rounded-[6px] border border-[#E9ECF2]">
                            <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-6">
                                <div className="flex flex-col gap-6 lg:border-r lg:border-[#E9ECF2] lg:pr-6">
                                    <div className="flex flex-col gap-4 pb-6 border-b border-[#E9ECF2]">
                                        <h3 className="text-2xl font-medium text-black">Order Summary</h3>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-medium text-secondary">Pay for a team plan:</p>
                                            <p className="text-sm font-normal text-black">TechByte Innovators</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 pb-6 border-b border-[#E9ECF2]">
                                        <h6 className="text-base font-medium text-secondary">Billing plan</h6>
                                        <div className="flex flex-col sm:flex-row justify-evenly items-center gap-[10px]">
                                            <BillCard plan={PLANS.YEARLY} discount="-18%" />
                                            <BillCard isChecked plan={PLANS.MONTHLY} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 pb-6 border-b border-[#E9ECF2]">
                                        <h6 className="text-base font-medium text-secondary">Payment details</h6>
                                        <button className="flex items-center gap-[6px]" onClick={() => setShowAddCard(true)}>
                                            <PlusIcon color="#5A8DF1" size={20} />
                                            <p className="text-base font-medium text-primary mt-[2px]">Add card</p>
                                        </button>
                                    </div>

                                    <div className="flex flex-col">
                                        <h6 className="text-base font-medium text-secondary mb-4">Monthly plan</h6>
                                        <div className="flex justify-between items-center border-y border-dashed border-[#E9ECF2] p-4">
                                            <p className="text-sm font-medium text-secondary">2 seats</p>
                                            <p className="text-sm font-medium text-black">$14/month</p>
                                        </div>
                                        <div className="flex justify-between items-center p-4">
                                            <p className="text-sm font-medium text-secondary">Unlimited guests</p>
                                            <p className="text-sm font-medium text-primary">FREE</p>
                                        </div>
                                        <div className="flex flex-col bg-primary100 border border-primary rounded-[4px]">
                                            <div className="flex justify-between items-center p-4 pb-[14px] border-b border-primary border-dashed">
                                                <p className="text-sm font-medium text-black">Billed today:</p>
                                                <p className="text-2xl font-medium text-black">$14</p>
                                            </div>
                                            <div className="flex justify-between items-center p-4 pt-[14px]">
                                                <p className="text-xs font-normal text-secondary">Renews for $14 every month</p>
                                            </div>
                                        </div>
                                    </div>

                                    <Button className="h-[55px]" onClick={onProceed}>
                                        Pay for a team plan
                                    </Button>
                                    <p className="text-sm text-center font-normal text-secondary">All transactions charged in USD</p>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <p className="text-base font-medium text-primary"><span className="capitalize">{PRICES[1].title}</span> plan</p>
                                    <div className="flex flex-col gap-[10px]">
                                        {PRICES[1].features.map((feature, index) => (
                                            <div key={index} className="flex items-center gap-[10px]">
                                                <PrimaryCheckIcon size={20} />
                                                <p className="text-sm font-normal text-secondary">{feature}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col gap-[12px] mt-[36px]">
                                        <h6 className="text-base font-normal text-secondary">We accept the following cards</h6>
                                        <div className="flex items-center gap-[6px]">
                                            {CARDS.map((card, index) => (
                                                <div key={index} className="w-[55.2px] h-[37px] flex items-center justify-center rounded-[4px]" style={{ backgroundColor: card.bg }}>
                                                    <img src={card.img} alt={card.alt} width={card.width} height={card.height} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAddCard && <AddCardModal onClose={() => setShowAddCard(false)} onSubmit={() => setShowAddCard(false)} />}
        </div>
    );
};

export default PaymentDialog;