import clsx from 'clsx';
import React from 'react'
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
    readonly traces: {
        title: string;
        icon: React.ReactNode;
        link: string;
    }[]
}

export default function Breadcrumb({ traces }: BreadcrumbProps) {
    return (
        <div className="flex flex-row items-center gap-[6px] min-w-max">
            {
                traces.map((trace, index) => (
                    <div key={index} className="flex flex-row items-center gap-[6px]">
                        {trace.icon}
                        <Link className={clsx("text-sm", index === traces.length - 1 ? "!text-black font-medium" : "font-normal text-secondary")} to={trace.link}>{trace.title}</Link>
                        {
                            index < traces.length - 1 && (
                                <span className="text-sm font-normal text-secondary">
                                    {'/'}
                                </span>
                            )
                        }
                    </div>
                ))
            } 
        </div>
    )
}
