/**
 * Boards selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.AppState || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (AppState) => AppState.isAuthenticated
);

export const selectAuthorizedAccounts = createSelector(
  [selectDomain],
  (AppState) => AppState.authorizedAccounts
);

export const selectUser = createSelector(
  [selectDomain],
  (AppState) => AppState.user
);

export const selectDrawerOpen = createSelector(
  [selectDomain],
  (AppState) => AppState.drawerOpen
);

export const selectLoading = createSelector(
  [selectDomain],
  (AppState) => AppState.loading
);
export const selectError = createSelector(
  [selectDomain],
  (AppState) => AppState.error
);

export const selectProjectDetails = createSelector(
  [selectDomain],
  (AppState) => AppState.project
);

export const selectTeamForm = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm
);

export const selectTeamProjectName = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm.project.name
);

export const selectTeamProjectDefaultCustomization = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm.project.defaultCustomization
);

export const selectTeamFormActiveIndex = createSelector(
  [selectDomain],
  (AppState) => AppState.teamFormActiveIndex
);
