import React from 'react'

interface SeverityIconProps {
    color?: string
    size?: number
}

export default function SeverityIcon({ color="#79808A", size=20 }: SeverityIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M5.54545 16.2273H6.04545V15.7273V11.9091V11.4091H5.54545H4.27273H3.77273V11.9091V15.7273V16.2273H4.27273H5.54545ZM6.27824 11.1763L6.63179 10.8228L6.27824 11.1763C6.30381 11.2019 6.31818 11.2366 6.31818 11.2727V16.3636C6.31818 16.3998 6.30382 16.4345 6.27824 16.4601C6.25267 16.4856 6.21798 16.5 6.18182 16.5H3.63636C3.6002 16.5 3.56551 16.4856 3.53994 16.4601C3.51437 16.4345 3.5 16.3998 3.5 16.3636V11.2727C3.5 11.2366 3.51437 11.2019 3.53994 11.1763C3.56551 11.1507 3.6002 11.1364 3.63636 11.1364H6.18182C6.21798 11.1364 6.25267 11.1507 6.27824 11.1763ZM15.7273 16.2273H16.2273V15.7273V9.36364V8.86364H15.7273H14.4545H13.9545V9.36364V15.7273V16.2273H14.4545H15.7273ZM10.6364 16.2273H11.1364V15.7273V4.27273V3.77273H10.6364H9.36364H8.86364V4.27273V15.7273V16.2273H9.36364H10.6364ZM13.8182 8.59091H16.3636C16.3998 8.59091 16.4345 8.60528 16.4601 8.63085C16.4856 8.65642 16.5 8.69111 16.5 8.72727V16.3636C16.5 16.3998 16.4856 16.4345 16.4601 16.4601C16.4345 16.4856 16.3998 16.5 16.3636 16.5H13.8182C13.782 16.5 13.7473 16.4856 13.7218 16.4601C13.6962 16.4345 13.6818 16.3998 13.6818 16.3636V8.72727C13.6818 8.69111 13.6962 8.65642 13.7218 8.63085C13.7473 8.60528 13.782 8.59091 13.8182 8.59091ZM8.72727 3.5H11.2727C11.3089 3.5 11.3436 3.51437 11.3692 3.53994C11.3947 3.56551 11.4091 3.6002 11.4091 3.63636V16.3636C11.4091 16.3998 11.3947 16.4345 11.3692 16.4601C11.3436 16.4856 11.3089 16.5 11.2727 16.5H8.72727C8.69111 16.5 8.65642 16.4856 8.63085 16.4601C8.60528 16.4345 8.59091 16.3998 8.59091 16.3636V3.63636C8.59091 3.6002 8.60528 3.56551 8.63085 3.53994C8.65642 3.51437 8.69111 3.5 8.72727 3.5Z" fill={color} stroke={color}/>
        </svg>
    )
}