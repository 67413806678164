import React from "react";

interface BoardsIcon {
  size?: number;
  color?: string;
}

const BoardsIcon: React.FC<BoardsIcon> = ({ size = 20, color = "#79808A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.34458 3.34458C2.73978 3.94939 2.4 4.76968 2.4 5.625L2.4 14.375C2.4 15.2303 2.73978 16.0506 3.34458 16.6554C3.94939 17.2602 4.76968 17.6 5.625 17.6H14.375C15.2303 17.6 16.0506 17.2602 16.6554 16.6554C17.2602 16.0506 17.6 15.2303 17.6 14.375V5.625C17.6 4.76968 17.2602 3.94939 16.6554 3.34458C16.0506 2.73978 15.2303 2.4 14.375 2.4L5.625 2.4C4.76968 2.4 3.94939 2.73978 3.34458 3.34458ZM4.36989 4.36989C4.70276 4.03701 5.15424 3.85 5.625 3.85L11.15 3.85V8.65H3.85L3.85 5.625C3.85 5.15424 4.03701 4.70276 4.36989 4.36989ZM3.85 14.375V10.1H11.15L11.15 16.15H5.625C5.15424 16.15 4.70276 15.963 4.36989 15.6301C4.03701 15.2972 3.85 14.8458 3.85 14.375ZM16.15 8.85V11.15H12.6V8.85L16.15 8.85ZM12.6 16.15V12.6H16.15V14.375C16.15 14.8458 15.963 15.2972 15.6301 15.6301C15.2972 15.963 14.8458 16.15 14.375 16.15H12.6ZM15.6301 4.36989C15.963 4.70276 16.15 5.15424 16.15 5.625V7.4L12.6 7.4V3.85H14.375C14.8458 3.85 15.2972 4.03701 15.6301 4.36989Z"
        fill="#79808A"
        stroke={color}
        stroke-width="0.2"
      />
    </svg>
  );
};

export default BoardsIcon;
