import React from "react";

export default function QAtestingIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1114 10.7136C11.9583 10.7682 11.6629 11.0185 10.0539 12.4694C8.00356 14.3151 8 14.3183 8 14.6618C8 15.0052 8 15.0084 10.0682 16.8638C11.8587 18.4719 11.9797 18.5747 12.1612 18.626C12.6098 18.7576 13.1224 18.5361 13.2897 18.1413C13.3609 17.968 13.3431 17.6502 13.2505 17.4865C13.2185 17.4255 12.5137 16.7643 11.6914 16.0196L10.1892 14.6618L11.6914 13.3072C12.5137 12.5593 13.2185 11.9013 13.2505 11.8403C13.3288 11.6958 13.3573 11.4069 13.3075 11.24C13.2505 11.0538 13.0512 10.8291 12.8696 10.7521C12.6347 10.6494 12.3285 10.6366 12.1114 10.7136Z"
        fill="#DC7290"
      />
      <path
        d="M16.4928 10.8171C16.2528 10.932 16.0903 11.1202 16.0374 11.3499C15.9915 11.5477 16.0303 11.7838 16.1327 11.9337C16.1645 11.9816 16.86 12.626 17.6756 13.3661L19.162 14.7156L17.6403 16.0937C15.935 17.641 15.9774 17.59 16.0091 17.9951C16.0374 18.3492 16.2563 18.5917 16.627 18.6906C16.8636 18.7512 17.1001 18.7352 17.2978 18.6427C17.372 18.6076 18.244 17.8452 19.3103 16.8785C21.3404 15.0442 21.3298 15.0505 21.3333 14.7156C21.3333 14.3742 21.3298 14.371 19.2997 12.5399C17.8768 11.2606 17.3932 10.8427 17.2731 10.7948C17.033 10.6959 16.7294 10.7055 16.4928 10.8171Z"
        fill="#DC7290"
      />
      <path
        d="M28.0003 28.0628L22.2096 22.2721M22.2096 22.2721C23.2001 21.2816 23.9858 20.1057 24.5219 18.8115C25.058 17.5173 25.3339 16.1302 25.3339 14.7294C25.3339 13.3286 25.058 11.9416 24.5219 10.6474C23.9858 9.35321 23.2001 8.17729 22.2096 7.18677C21.2191 6.19626 20.0432 5.41054 18.749 4.87447C17.4548 4.33841 16.0677 4.0625 14.6669 4.0625C13.2661 4.0625 11.8791 4.33841 10.5849 4.87447C9.29071 5.41054 8.11479 6.19626 7.12427 7.18677C5.12384 9.18721 4 11.9004 4 14.7294C4 17.5585 5.12384 20.2717 7.12427 22.2721C9.12471 24.2725 11.8379 25.3964 14.6669 25.3964C17.496 25.3964 20.2092 24.2725 22.2096 22.2721Z"
        stroke="#DC7290"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
