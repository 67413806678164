// // src/app/containers/Features/containers/User/redux/saga.ts
import { put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { TICKET_GROUPS } from "../../../data";
import toastService from "utils/toast";

function* fetchTicketGroupsSaga() {
  try {
    yield put(actions.setGroups(TICKET_GROUPS));
  } catch (error) {
    toastService.error("Failed to fetch ticket groups");
  }
  yield put(actions.setIsLoading(false));
}

export default function* ticketGroupsSaga() {
  yield takeLatest(actions.fetchTicketGroups, fetchTicketGroupsSaga);
}
