import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
 
const TooltipProvider = TooltipPrimitive.Provider
const Tooltip = TooltipPrimitive.Root
const TooltipTrigger = TooltipPrimitive.Trigger
 
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={`z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 ${className}`}
    {...props}
  >
    <TooltipPrimitive.Arrow className="fill-[#333E4D]" />
    <div className="relative">
      {props.children}
    </div>
  </TooltipPrimitive.Content>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName
 
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }

interface CustomTooltipProps {
    children: React.ReactNode;
    text: string | React.ReactNode;
    className?: string;
    position?: "top" | "bottom" | "left" | "right";
}

export default function CustomTooltip({
    children,
    text,
    className,
    position = "top"
}: CustomTooltipProps) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className={className}>
                    {children}
                </TooltipTrigger>
                <TooltipContent className="bg-black rounded-[4px] border-none" side={position}>
                    <p className="text-sm text-white leading-[21px] font-normal">{text}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}