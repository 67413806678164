import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { AuthorizedAccount, ContainerState, PLAN_TYPE, User } from "../types";
import { authorizedAccounts } from "app/containers/Auth/data";
import { FormError } from "app/types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  authorizedAccounts: authorizedAccounts,
  user: null,
  loading: false,
  error: null,
  drawerOpen: false,
  teamFormActiveIndex: 1,
  project: null,
  teamForm: {
    name: {
      value: "",
      error: "",
    },
    project: {
      name: {
        value: "",
        error: "",
      },
      defaultCustomization: {
        agileDevelopmentAndSprints: false,
        epicsAndStories: false,
        releaseManagement: false,
      },
    },
    members: [
      {
        name: {
          value: "",
          error: "",
        },
        role: {
          value: "",
          error: "",
        },
      },
    ],
    plan: PLAN_TYPE.BUSINESS,
    coupon: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
};

const formSlice = createSlice({
  name: "AppState",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAuthorizedAccounts: (
      state,
      action: PayloadAction<AuthorizedAccount[]>
    ) => {
      state.authorizedAccounts = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.loading = false;
    },
    openDrawer(state, action: PayloadAction<any>) {
      state.drawerOpen = true;
      state.drawerPayload = action.payload || null;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
      state.drawerPayload = null;
    },
    fetchUser(state) {
      state.loading = true;
      state.error = null;
    },
    logoutUser(state) {
      state.user = null;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = false;
    },
    fetchUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setTeamFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.teamForm[action.payload.name].value = action.payload.value;
      state.teamForm[action.payload.name].error = "";
    },
    setTeamPlan: (state, action: PayloadAction<{ value: PLAN_TYPE }>) => {
      state.teamForm.plan = action.payload.value;
    },
    setTeamFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        state.teamForm[error.name].error = error.error;
      });
    },
    setTeamProjectName: (state, action: PayloadAction<{ value: string }>) => {
      state.teamForm.project.name.value = action.payload.value;
      state.teamForm.project.name.error = "";
    },
    setTeamProjectDefaultCustomization: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.teamForm.project.defaultCustomization[action.payload.name] =
        action.payload.value;
    },
    setTeamProjectNameError: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.teamForm.project.name.error = action.payload.error;
    },
    addTeamMember: (state) => {
      state.teamForm.members = [
        ...state.teamForm.members,
        {
          name: {
            value: "",
            error: "",
          },
          role: {
            value: "",
            error: "",
          },
        },
      ];
    },
    setTeamCoupon: (state, action: PayloadAction<{ value: string }>) => {
      state.teamForm.coupon.value = action.payload.value;
      state.teamForm.coupon.error = "";
    },
    removeTeamMember: (state, action: PayloadAction<{ index: number }>) => {
      state.teamForm.members = state.teamForm.members.filter(
        (_, index) => index !== action.payload.index
      );
    },
    updateTeamMember: (
      state,
      action: PayloadAction<{ index: number; name: string; value: string }>
    ) => {
      state.teamForm.members[action.payload.index][action.payload.name].value =
        action.payload.value;
      state.teamForm.members[action.payload.index][action.payload.name].error =
        "";
    },
    updateTeamMemberError: (
      state,
      action: PayloadAction<{ index: number; name: string; error: string }>
    ) => {
      state.teamForm.members[action.payload.index][action.payload.name].error =
        action.payload.error;
    },
    saveTeamRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.teamForm.isLoading = true;
    },
    saveTeamRequestComplete: (state) => {
      state.teamForm.isLoading = false;
    },
    nextStep: (state) => {
      state.teamFormActiveIndex = Math.min(state.teamFormActiveIndex + 1, 4);
    },
    previousStep: (state) => {
      state.teamFormActiveIndex = Math.max(state.teamFormActiveIndex - 1, 1);
    },
    setTeamFormActiveIndex: (state, action: PayloadAction<number>) => {
      state.teamFormActiveIndex = action.payload;
    },
  },
});

export const { actions, reducer: appReducer, name: appSlice } = formSlice;
