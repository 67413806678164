import React from "react";

interface MenuIconsProps {
  size: string;
  color: string;
  strokeColor?: string;
}

export default function MenuIcons({
  size,
  color,
  strokeColor = "#FFF",
}: MenuIconsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="0.5"
        y="-0.5"
        width="19"
        height="19"
        rx="1.5"
        transform="matrix(1 0 0 -1 0 19)"
        stroke={strokeColor}
      />
      <path
        d="M4.16667 11.6641C3.25 11.6641 2.5 10.9141 2.5 9.9974C2.5 9.08073 3.25 8.33073 4.16667 8.33073C5.08333 8.33073 5.83333 9.08073 5.83333 9.9974C5.83333 10.9141 5.08333 11.6641 4.16667 11.6641ZM15.8333 11.6641C14.9167 11.6641 14.1667 10.9141 14.1667 9.9974C14.1667 9.08073 14.9167 8.33073 15.8333 8.33073C16.75 8.33073 17.5 9.08073 17.5 9.9974C17.5 10.9141 16.75 11.6641 15.8333 11.6641ZM10 11.6641C9.08333 11.6641 8.33333 10.9141 8.33333 9.9974C8.33333 9.08073 9.08333 8.33073 10 8.33073C10.9167 8.33073 11.6667 9.08073 11.6667 9.9974C11.6667 10.9141 10.9167 11.6641 10 11.6641Z"
        fill={color}
      />
    </svg>
  );
}
