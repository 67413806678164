import React from 'react'
import { Board } from '../../../types'
import Avatar from 'app/containers/Features/components/Avatar'
import { dateFormatter, formatDateDistance } from 'utils/helpers'
import Options from './Options'
import CustomTooltip from 'app/components/Tooltip'
import EditableRowItem from './EditableRowItem'

interface RowProps {
    board: Board
    onArchive: () => void
    onDuplicate?: () => void
    onFavorite?: () => void
}

export default function Row({ board, onArchive }: RowProps) {
    // const navigate = useNavigate()
    // const handleClick = () => {
    //     navigate(`/app/boards/${board.id}/board`)
    // }
    return (
        <tr className="cursor-pointer border-b border-[#E9ECF2] hover:bg-[#ECEEF2] group overflow-visible">
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D] flex items-center flex-row min-w-[300px] overflow-visible">
                <div className='flex items-center flex-row gap-[6px] relative h-full'>
                    <Options 
                        onArchive={onArchive}
                    />
                    <EditableRowItem board={board} />
                </div>
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">
                <CustomTooltip text={dateFormatter(board.lastViewed)}>
                    <span>{formatDateDistance(board.lastViewed)}</span>
                </CustomTooltip>
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">
                <CustomTooltip text={dateFormatter(board.lastUpdated)}>
                    <span>{formatDateDistance(board.lastUpdated)}</span>
                </CustomTooltip>
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">
                <Avatar 
                    name={board.owner.name} 
                    color={board.owner.color} 
                    caption={board.owner.role}
                />
            </td>
            <td className="px-4 pr-2 py-[12px] text-left text-sm leading-[21px] font-normal text-[#333E4D]">
                <CustomTooltip text={dateFormatter(board.createdAt)}>
                    <span>{dateFormatter(board.createdAt)}</span>
                </CustomTooltip>
            </td>
        </tr>
    )
}
