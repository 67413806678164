import React, { useState } from 'react'
import Button from 'app/components/Button';
import { Link, useNavigate } from 'react-router-dom';
import Otp from 'app/components/OTP';
import { selectUser } from 'app/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { validateOtp } from '../helpers';
import { selectVerifyEmailForm } from '../redux/selector';
import Logo from 'app/components/Icons/Logo';

export default function VerifyEmail() {
    const [isManual, setIsManual] = useState(false);
    const user = useSelector(selectUser);   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const verifyEmailForm = useSelector(selectVerifyEmailForm);
    
    const handleSubmit = (code: string) => {
        const isValid = validateOtp(code);
        if(!isValid) {
            dispatch(actions.setVerifyEmailFormError("Enter 6 digits code"));
            return;
        }
        dispatch(actions.verifyEmailRequest({code, callback: () => {
            navigate('/onboarding/create-profile');
        }}));
    }

    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72}  />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Check your email</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full max-w-[390px] mb-[56px]'>
                <p className='text-secondary text-md leading-[24px] text-center font-normal'>We’ve sent a temporary login link. Please check your inbox at <span className='font-medium text-black'>{user?.email}</span></p>
                {
                    isManual ?
                    <Otp 
                        isLoading={verifyEmailForm.isLoading}
                        disabled={verifyEmailForm.isLoading}
                        onSubmit={handleSubmit}
                        error={verifyEmailForm.code.error}
                    /> :
                    <Button 
                        onClick={() => setIsManual(true)}
                        type='button'
                        className='w-full h-[56px] border border-secondary text-secondary bg-white'
                    >
                        Enter code manually
                    </Button>
                }
                <div className='flex justify-center'>
                    <Link to="/auth/login" className="text-center text-primary text-md leading-[24px] font-medium flex flex-row items-center">Back to login</Link>
                </div>
            </form>
        </div>
    )
}