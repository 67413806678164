import React from "react";

interface RelationsIconProps {
    size?: string;
    color?: string;
}

const RelationsIcon = ({ size = "20", color = "#79808A" }: RelationsIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M12.7 3.7C12.7 4.41608 12.4155 5.10284 11.9092 5.60919C11.4028 6.11554 10.7161 6.4 10 6.4C9.28392 6.4 8.59716 6.11554 8.09081 5.60919C7.58446 5.10284 7.3 4.41608 7.3 3.7C7.3 2.98392 7.58446 2.29716 8.09081 1.79081C8.59716 1.28446 9.28392 1 10 1C10.7161 1 11.4028 1.28446 11.9092 1.79081C12.4155 2.29716 12.7 2.98392 12.7 3.7ZM6.4 16.3C6.4 17.0161 6.11554 17.7028 5.60919 18.2092C5.10284 18.7155 4.41608 19 3.7 19C2.98392 19 2.29716 18.7155 1.79081 18.2092C1.28446 17.7028 1 17.0161 1 16.3C1 15.5839 1.28446 14.8972 1.79081 14.3908C2.29716 13.8845 2.98392 13.6 3.7 13.6C4.41608 13.6 5.10284 13.8845 5.60919 14.3908C6.11554 14.8972 6.4 15.5839 6.4 16.3ZM19 16.3C19 17.0161 18.7155 17.7028 18.2092 18.2092C17.7028 18.7155 17.0161 19 16.3 19C15.5839 19 14.8972 18.7155 14.3908 18.2092C13.8845 17.7028 13.6 17.0161 13.6 16.3C13.6 15.5839 13.8845 14.8972 14.3908 14.3908C14.8972 13.8845 15.5839 13.6 16.3 13.6C17.0161 13.6 17.7028 13.8845 18.2092 14.3908C18.7155 14.8972 19 15.5839 19 16.3Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.7 3.7C12.7 4.41608 12.4155 5.10284 11.9092 5.60919C11.4028 6.11554 10.7161 6.4 10 6.4C9.28392 6.4 8.59716 6.11554 8.09081 5.60919C7.58446 5.10284 7.3 4.41608 7.3 3.7C7.3 2.98392 7.58446 2.29716 8.09081 1.79081C8.59716 1.28446 9.28392 1 10 1C10.7161 1 11.4028 1.28446 11.9092 1.79081C12.4155 2.29716 12.7 2.98392 12.7 3.7ZM6.4 16.3C6.4 17.0161 6.11554 17.7028 5.60919 18.2092C5.10284 18.7155 4.41608 19 3.7 19C2.98392 19 2.29716 18.7155 1.79081 18.2092C1.28446 17.7028 1 17.0161 1 16.3C1 15.5839 1.28446 14.8972 1.79081 14.3908C2.29716 13.8845 2.98392 13.6 3.7 13.6C4.41608 13.6 5.10284 13.8845 5.60919 14.3908C6.11554 14.8972 6.4 15.5839 6.4 16.3ZM19 16.3C19 17.0161 18.7155 17.7028 18.2092 18.2092C17.7028 18.7155 17.0161 19 16.3 19C15.5839 19 14.8972 18.7155 14.3908 18.2092C13.8845 17.7028 13.6 17.0161 13.6 16.3C13.6 15.5839 13.8845 14.8972 14.3908 14.3908C14.8972 13.8845 15.5839 13.6 16.3 13.6C17.0161 13.6 17.7028 13.8845 18.2092 14.3908C18.7155 14.8972 19 15.5839 19 16.3Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.0031 6.79687V9.99844M10.0031 9.99844H6.85313C5.36813 9.99844 4.62563 9.99844 4.16483 10.3944C3.70313 10.7886 3.70313 11.4249 3.70313 12.6984V13.5984M10.0031 9.99844L13.1531 9.99844C14.6381 9.99844 15.3806 9.99844 15.8414 10.3944C16.3031 10.7886 16.3031 11.4249 16.3031 12.6984V13.5984" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default RelationsIcon;