export enum TeamPlanType {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export enum TeamStatus {
  ACTIVE = "active",
  TODO = "TO DO",
  IN_PROGRESS = "IN PROGRESS",
  DONE = "DONE",
  REOPENED = "REOPENED",
  IN_CODE_REVIEW = "IN CODE REVIEW",
  ABANDONED = "ABANDONED",
  IN_STAGING = "IN STAGING",
  TESTING_IN_PRODUCTION = "TESTING IN PRODUCTION",
}

export enum ProjectStatus {
  ACTIVE = "Active",
  IN_ACTIVE = "Inactive",
}

export enum MemberStatus {
  ACTIVE = "Active",
  INVITATION_PENDING = "Invitation pending",
  INVITATION_REJECTED = "Invitation rejected",
}

export interface IIcon {
  initials: string;
  color: string;
  bgColor: string;
}

export interface IMember {
  id: string;
  name: string;
  icon: IIcon;
  email: string;
  role: string;
  status: MemberStatus;
  lastLogin: string;
}

export interface IProjectProgress {
  current: number;
  total: number;
}

export interface IBoard {
  name: string;
}

export interface IComponent {
  id:string;
  name: string;
  lead?: IMember;
  defaultAssignee?: IMember;
  description?: string;
  isArchived: boolean;
  creationDate: ISODateString;
}

type ProjectID = IProject["id"];
type ISODateString = Date;

export interface IStorageUsage {
  current: number;
  total: number;
}

export interface IProject {
  id: string;
  name: string;
  icon: IIcon;
  owner?: IMember;
  projectLead?: IMember;
  defaultAssignee?: IMember;
  status: ProjectStatus;
  isHidden: boolean;
  isArchived: boolean;
  progress: IProjectProgress;
  members?: IMember[];
  boards?: IBoard[];
  features: string[];
  components: IComponent[];
  description?: string;
  creationDate: ISODateString;
  startDate?: ISODateString;
  endDate?: ISODateString;
}

export interface ITeam {
  id: string;
  name: string;
  icon: IIcon;
  owner: IMember;
  plan: TeamPlanType;
  statuses: TeamStatus[];
  members: IMember[];
  projects: IProject[];
  activeProjectId: ProjectID;
  creationDate: ISODateString;
  storageUsage: IStorageUsage;
}

export interface ITeamInitial {
  loading: boolean;
  error: Error | null;
  teams: Array<ITeam>;
  selectedTeam: ITeam | null;
  selectedProject: IProject | null;
}

export type ComponentFormType = {
  id:string;
  componentName: string;
  lead: string | undefined;
  defaultAssignee: string | undefined;
  description: string | undefined;
};
