import React from "react";

interface ChevronUpIconProps {
  size: number;
  color?: string;
}

export default function ChevronUpIcon({
  size = 20,
  color = "#79808A",
}: ChevronUpIconProps) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
      >
        <path
          d="M14.1668 7.49984L10.0002 11.6665L5.8335 7.49984"
          stroke="#5A8DF1"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
