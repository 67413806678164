// // src/app/containers/Features/containers/User/redux/saga.ts
import { put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { userDetailsData, memberData, FavList } from "../data";
import { User } from "../types";
import { selectUser } from "./selector";
import { PROJECTS } from "app/containers/Features/containers/Boards/data";

// Selector to get the current index
const selectTeamFormActiveIndex = (state: any) =>
  state.AppState.teamFormActiveIndex;

function* handleNextStep() {
  // Retrieve the current index from the state
  const prevIndex: number = yield select(selectTeamFormActiveIndex);

  // Calculate the next index
  const nextIndex = Math.min(prevIndex + 1, 4);

  // Dispatch the action with the new index
  yield put(actions.setTeamFormActiveIndex(nextIndex));
}

function* handlePreviousStep() {
  // Retrieve the current index from the state
  const prevIndex: number = yield select(selectTeamFormActiveIndex);

  // Calculate the previous index
  const prevStepIndex = Math.max(prevIndex - 1, 1);

  // Dispatch the action with the new index
  yield put(actions.setTeamFormActiveIndex(prevStepIndex));
}

function* fetchUserDetailsSaga() {
  try {
    // Simulate API call with static data
    const currentUserState = yield select(selectUser);
    const data: User = {
      ...currentUserState,
      name: userDetailsData.name,
      initials: userDetailsData.initials,
      email: userDetailsData.email,
      role: userDetailsData.role,
      status: userDetailsData.status,
      lastLogin: userDetailsData.lastLogin,
      localTime: userDetailsData.localTime,
      location: memberData[0].location,
      spokenLanguages: memberData[0].spokenLanguages.split(", "),
      creationDate: memberData[0].creationDate,
      mobileNumber: memberData[0].mobileNumber,
      members: memberData,
      project: PROJECTS,
    };

    yield put(actions.setUser(data));
  } catch (error) {
    yield put({ type: "FETCH_USER_DETAILS_FAILURE", error });
  }
}

export default function* appSaga() {
  yield takeLatest(actions.fetchUser.type, fetchUserDetailsSaga);
  yield takeLatest("onboarding/nextStep", handleNextStep);
  yield takeLatest("onboarding/previousStep", handlePreviousStep);
}
