import React from "react";

export default function AgileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
    >
      <path
        d="M22.3346 24.3307H28.3346M28.3346 24.3307L25.0013 20.9974M28.3346 24.3307L25.0013 27.6641M15.0013 1.66406L11.668 4.9974L15.0013 8.33073"
        stroke="#6BD60E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0013 5C15.6535 5 18.197 6.05357 20.0724 7.92893C21.9477 9.8043 23.0013 12.3478 23.0013 15C23.0013 17.6522 21.9477 20.1957 20.0724 22.0711C18.197 23.9464 15.6535 25 13.0013 25H1.66797"
        stroke="#6BD60E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.008 6.32812C6.48538 7.20534 5.22079 8.46829 4.34161 9.98977C3.46242 11.5113 2.99968 13.2376 3 14.9948C3 17.2455 3.744 19.3228 5 20.9948"
        stroke="#6BD60E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
