import OrderIcon from "app/components/Icons/OrderIcon";
import React, { useState } from "react";
import TicketsFilter from "./TicketsFilter";
import SelectedFilter from "./SelectedFilter";
import { CheckIcon, EditUserIcon } from "app/components/Icons";
import CalendarIcon from "app/components/Icons/CalendarIcon";
import ComponentIcon from "app/components/Icons/ComponentIcon";
import EnvironmentIcon from "app/components/Icons/EnvironmentIcon";
import FlagIcon from "app/components/Icons/FlagIcon";
import ReleaseIcon from "app/components/Icons/ReleaseIcon";
import SeverityIcon from "app/components/Icons/SeverityIcon";
import SprintIcon from "app/components/Icons/SprintIcon";
import StatusIcon from "app/components/Icons/StatusIcon";
import TargetReleaseIcon from "app/components/Icons/TargetReleaseIcon";
import TicketsIcon from "app/components/Icons/TicketsIcon";
import UserIcon from "app/components/Icons/UserIcon";
import IconText from "app/components/IconText";
import ResetIcon from "app/components/Icons/ResetIcon";
import SearchButton from "./SearchButton";

export const Filters = [
    {
        id: "ticket_type",
        name: "Ticket type",
        icon: <TicketsIcon color='#79808A' size={20} />,
        options: [
            "Tasks",
            "Bugs",
            "Stories",
            "Epics",
        ]
    },
    {
        id: "status",
        name: "Status",
        icon: <StatusIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "due_date",
        name: "Due Date",
        icon: <CalendarIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "priority",
        name: "Priority",
        icon: <FlagIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "release",
        name: "Release",
        icon: <ReleaseIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "target_release",
        name: "Target release",
        icon: <TargetReleaseIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "sprint",
        name: "Sprint",
        icon: <SprintIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "environment",
        name: "Environment",
        icon: <EnvironmentIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "severity",
        name: "Severity",
        icon: <SeverityIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "component",
        name: "Component",
        icon: <ComponentIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "assignee",
        name: "Assignee",
        icon: <UserIcon color='#79808A' size={20} />,
        options: []
    },
    {
        id: "creator",
        name: "Creator",
        icon: <EditUserIcon color='#79808A' size="20" />,
        options: []
    },
    {
        id: "creation_date",
        name: "Creation date",
        icon: <CalendarIcon color='#79808A' size={20} />,
        options: []
    }
]

export default function FilterBar() {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedFilters, setSelectedFilters] = useState<typeof Filters>([]);
    return (
        <div className="flex flex-row gap-[10px] justify-between">
            <div className="flex flex-row gap-[10px]">
                <SearchButton value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                <button className="min-w-[32px] size-[32px] bg-white border border-[#E9ECF2] rounded-[4px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none">
                    <OrderIcon size={20} color="#79808A" />
                </button>
                <div className="border border-[#E9ECF2] h-[32px] w-[0.5px]" />
                <div className="flex flex-row items-center gap-[10px] flex-wrap">
                    <SelectedFilter options={selectedFilters} selectedOptions={selectedOptions} onSelectOption={(option) => setSelectedOptions(option)} />
                    <TicketsFilter options={Filters} selectedFilters={selectedFilters} onSelectFilter={(filter) => setSelectedFilters(filter)} />
                </div>
            </div>
            {
                selectedFilters.length > 0 && (
                    <div className="flex flex-row gap-[10px]">
                        <button className="text-sm font-normal text-secondary h-[32px] flex flex-row items-center gap-[6px] justify-center px-[10px]">
                            <IconText 
                                icon={<ResetIcon size={20} color="#79808A" />} 
                                text="Reset"
                                className="text-sm font-medium mt-[2px] text-secondary"
                            />
                        </button>
                        <button className="text-sm font-normal text-secondary h-[32px] flex flex-row items-center gap-[6px] justify-center bg-primary100 text-primary border border-primary rounded-[4px] px-[10px]">
                            <IconText 
                                icon={<CheckIcon size={20} color="#5A8DF1" />} 
                                text="Save"
                                className="text-sm font-medium mt-[2px] text-primary"
                            />
                        </button>
                    </div>
                )
            }
        </div>
    )
}